import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { Country, CountryByUserLng } from "../lib/types";
import { getCountries } from "../react-query/queries";
import { useMemo } from "react";

const useCountries = () => {
  const language = useIntl().locale;
  const { data } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  const countries: Country[] = useMemo(() => {
    if (!data) return [];

    return data?.countries;
  }, [data]);

  const countriesByUserLng: CountryByUserLng[] = useMemo(() => {
    return countries.map((item) => ({
      countryCode: item.countryCode,
      countryName: item[`countryName${language.toUpperCase()}` as keyof Country],
    }));
  }, [countries, language]);

  return {
    countries,
    countriesByUserLng,
  };
};

export default useCountries;
