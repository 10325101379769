import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { selectedRadioButton } from "../../utils/selectedRadioButton";

const useTrackHighLowOption = () => {
  const { getValues, watch } = useFormContext();
  const exchangeOptionsKey = "retirementOption.highLowLowHigh.exchangeOptions";
  const numberOfMonthsKey = "retirementOption.highLowLowHigh.numberOfMonths";

  const exchangeOptionSelected = selectedRadioButton(getValues(exchangeOptionsKey));
  const numberOfMonthsInput = getValues(numberOfMonthsKey);
  const [watchExchangeOptions, watchNumberOfMonths] = watch([exchangeOptionsKey, numberOfMonthsKey]);
  const watchExchangeOptionsValue = selectedRadioButton(watchExchangeOptions);

  const [highLowOptionChanges, setHighLowOptionChanges] = useState(false);
  const [exchangeOptions, setExchangeOptions] = useState(exchangeOptionSelected);
  const [numberOfMonths, setNumberOfMonths] = useState(numberOfMonthsInput);

  const isFieldsChanges =
    watchExchangeOptionsValue === exchangeOptions && watchNumberOfMonths === numberOfMonths;

  useEffect(() => {
    if (watchExchangeOptionsValue === exchangeOptions) return;
    setExchangeOptions(watchExchangeOptionsValue);
  }, [watchExchangeOptionsValue, exchangeOptions, setExchangeOptions]);

  useEffect(() => {
    if (watchNumberOfMonths === numberOfMonths) return;
    setNumberOfMonths(watchNumberOfMonths);
  }, [watchNumberOfMonths, numberOfMonths, setNumberOfMonths]);

  useEffect(() => {
    if (isFieldsChanges) return;
    setHighLowOptionChanges(true);
  }, [isFieldsChanges, setHighLowOptionChanges]);

  return highLowOptionChanges;
};

export default useTrackHighLowOption;
