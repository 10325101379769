import { IntlShape } from "react-intl";
import { EarlierLaterFully } from "../lib/constants";
import { ExchangeType, GenericRadioItem, Scenario, UseMaximumExchange } from "../lib/types";

export interface FormScenario {
  employmentOptions: {
    earningSalaryOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
      salary?: number;
    };
    quittingWorkOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
    };
    workingTimeOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
      hours?: number;
    };
  };
  retirementOption: {
    earlierOrLaterRetirementOptions: {
      fullyOrPartial: {
        id: string;
        label: string;
        value: number;
      };
      isEarlierOrLaterRetirementSelected?: boolean;
      fullAgeYear?: number;
      fullAgeMonth?: number;
      partialAgeYear?: number;
      partialAgeMonth?: number;
      percentage?: number;
    };
    exchangeOption: {
      isSelected?: boolean;
      isMaxExchange?: GenericRadioItem[];
      exchangeType?: GenericRadioItem[];
      exchangeAmount?: string;
    };
    bridgingOption: {
      isSelected?: boolean;
      maxExchangeAowAmount?: number;
      exchangeAmount?: string;
      isMaxExchangeAOW?: boolean;
    };
    highLowLowHigh: {
      isSelected?: boolean;
      exchangeOptions?: GenericRadioItem[];
      numberOfMonths?: number;
    };
    surrenderOptions: {
      isSurrenderSelected?: boolean;
      isRefrainFromSurrenderSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
    };
  };
}

const createEmploymentOptions = (scenario: Scenario): FormScenario["employmentOptions"] => {
  const {
    salaryStartYear,
    salaryStartMonth,
    salary,
    quitWorkingStartYear,
    quitWorkingStartMonth,
    partTimePercentageStartYear,
    partTimePercentageStartMonth,
    partTimePercentage,
  } = scenario;
  return {
    workingTimeOption: {
      ...(partTimePercentageStartYear !== null && { ageYear: partTimePercentageStartYear }),
      ...(partTimePercentageStartMonth !== null && { ageMonth: partTimePercentageStartMonth }),
      ...(partTimePercentage !== null && partTimePercentage !== 1 && { hours: partTimePercentage * 38 }),
    },
    earningSalaryOption: {
      ...(salaryStartYear !== null && { ageYear: salaryStartYear }),
      ...(salaryStartMonth !== null && { ageMonth: salaryStartMonth }),
      ...(salary !== null && { salary: salary }),
    },
    quittingWorkOption: {
      ...(quitWorkingStartYear !== null && { ageYear: quitWorkingStartYear }),
      ...(quitWorkingStartMonth !== null && { ageMonth: quitWorkingStartMonth }),
    },
  };
};

const createExchangeRadioButtons = (value: string, intl: IntlShape): GenericRadioItem[] => {
  const isHL = value === "HL";
  return [
    {
      id: "HL",
      checked: isHL,
      value: "HL",
      label: intl.formatMessage({ id: "planner.exchange-overtime.high-than-low" }),
    },
    {
      id: "LH",
      checked: !isHL,
      value: "LH",
      label: intl.formatMessage({ id: "planner.exchange-overtime.low-than-high" }),
    },
  ];
};

const createExchangeType = (value: string): GenericRadioItem[] => {
  return [
    {
      id: ExchangeType.OPPP,
      checked: value === ExchangeType.OPPP,
      value: ExchangeType.OPPP,
    },
    {
      id: ExchangeType.PPOP,
      checked: value === ExchangeType.PPOP,
      value: ExchangeType.PPOP,
    },
  ];
};

const createIsMaxExchange = (isMaxExchangeOPPP: boolean): GenericRadioItem[] => {
  return [
    {
      id: UseMaximumExchange.NO,
      checked: isMaxExchangeOPPP === false,
      value: UseMaximumExchange.NO,
    },
    {
      id: UseMaximumExchange.YES,
      checked: isMaxExchangeOPPP === true,
      value: UseMaximumExchange.YES,
    },
  ];
};

const createRetirementOption = (scenario: Scenario, intl: IntlShape): FormScenario["retirementOption"] => {
  const {
    retirementStartYear,
    retirementStartMonth,
    partlyRetirementStartYear,
    partlyRetirementStartMonth,
    partlyRetirementPercentage,
    isMaxExchangeOPPP,
    isMaxExchangeAOW,
    differentHeightsOverTime,
    differentHeightsEndYearInMonth,
    exchangeEntitlementsOption,
    exchangeEntitlementsAmount,
    bridgingAmount,
    convertSurrenderToNormal,
    surrenderYear,
    surrenderMonth,
  } = scenario;

  const FULLY = intl.formatMessage({ id: "utils.fully" });
  const percentageLabel = partlyRetirementPercentage !== 100 ? `${partlyRetirementPercentage}0%` : FULLY;

  return {
    earlierOrLaterRetirementOptions: {
      fullyOrPartial: {
        id: partlyRetirementPercentage?.toString() ?? EarlierLaterFully.toString(),
        label: percentageLabel.toString(),
        value: partlyRetirementPercentage ?? EarlierLaterFully,
      },
      ...(retirementStartYear !== null && { fullAgeYear: retirementStartYear }),
      ...(retirementStartMonth !== null && { fullAgeMonth: retirementStartMonth }),
      ...(partlyRetirementStartYear !== null && { partialAgeYear: partlyRetirementStartYear }),
      ...(partlyRetirementStartMonth !== null && { partialAgeMonth: partlyRetirementStartMonth }),
    },
    exchangeOption: {
      ...(exchangeEntitlementsAmount !== null &&
        isMaxExchangeOPPP !== null && {
          isMaxExchange: createIsMaxExchange(isMaxExchangeOPPP),
        }),
      ...(exchangeEntitlementsOption !== null && {
        exchangeType: createExchangeType(exchangeEntitlementsOption),
      }),
      ...(exchangeEntitlementsAmount !== null && {
        exchangeAmount: (Math.floor((exchangeEntitlementsAmount / 12) * 100) / 100).toString(),
      }),
    },
    bridgingOption: {
      ...(isMaxExchangeAOW !== null && { isMaxExchangeAOW: isMaxExchangeAOW }),
      ...(bridgingAmount !== null &&
        bridgingAmount !== 0 && {
          exchangeAmount: (Math.floor((bridgingAmount / 12) * 100) / 100).toString(),
        }),
    },
    highLowLowHigh: {
      ...(differentHeightsOverTime !== null && {
        exchangeOptions: createExchangeRadioButtons(differentHeightsOverTime, intl),
      }),
      ...(differentHeightsEndYearInMonth !== null && { numberOfMonths: differentHeightsEndYearInMonth }),
    },
    surrenderOptions: {
      isRefrainFromSurrenderSelected: convertSurrenderToNormal,
      isSurrenderSelected: !convertSurrenderToNormal,
      ...(surrenderYear !== null && { ageYear: surrenderYear }),
      ...(surrenderMonth !== null && { ageMonth: surrenderMonth }),
    },
  };
};

type employmentSection =
  | "bridgingOption"
  | "earlierOrLaterRetirementOptions"
  | "exchangeOption"
  | "surrenderOptions"
  | "highLowLowHigh";

// Add isSelected attribute to each sub-object of employmentOptions and retirementOptions (except surrenderOptions)
const addIsSelectedAttribute = (
  options: Record<string, FormScenario["retirementOption"][employmentSection]>
) => {
  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const subObject = options[key];
      const isEmpty = Object.keys(subObject).length === 0;
      const hasOnlyDefaultProp = (prop: string) => Object.keys(subObject).every((attr) => attr === prop);

      switch (key) {
        case "bridgingOption":
          options[key] = {
            ...subObject,
            isSelected: !hasOnlyDefaultProp("isMaxExchangeAOW"),
          };
          break;
        case "earlierOrLaterRetirementOptions":
          options[key] = {
            ...subObject,
            isEarlierOrLaterRetirementSelected: !hasOnlyDefaultProp("fullyOrPartial"),
          };
          break;
        case "surrenderOptions":
          options[key] = {
            ...subObject,
            isRefrainFromSurrenderSelected: hasOnlyDefaultProp("isRefrainFromSurrenderSelected"),
            isSurrenderSelected: !hasOnlyDefaultProp("isRefrainFromSurrenderSelected"),
          };
          break;
        default:
          options[key] = {
            ...subObject,
            isSelected: !isEmpty,
          };
          break;
      }
    }
  }
};

const formatScenarioToForm = (scenario: Scenario, intl: IntlShape): FormScenario => {
  const newScenario: FormScenario = {
    employmentOptions: createEmploymentOptions(scenario),
    retirementOption: createRetirementOption(scenario, intl),
  };

  addIsSelectedAttribute(newScenario.employmentOptions);
  addIsSelectedAttribute(newScenario.retirementOption);

  return newScenario;
};

export default formatScenarioToForm;
