import { FormattedMessage } from "react-intl";
import { PageLink } from "../lib/types";

/* cspell: disable */
export enum HREF {
  HOME = "/",
  PLANNER = "/planner",
  ACTIONS = "/actions",
  DOCUMENTS = "/documents",
  CONTACT = "/contact",
  MY_DATA = "/mydata",
  USER_DETAILS_FORM = "/mydata/userdetailsform",
  CONTACT_DETAILS_FORM = "/mydata/contactdetailsform",
  PARTNER_DETAILS_FORM = "/mydata/partnerdetailsform",
  LOGIN = "/login",
  LOGIN_PARTNER = "/loginpartner",
  LOGOUT = "/logout",
  CALLBACK = "/callback",
  CONTACT_SUPPORT = "/contactsupport",
  CONTACT_SUPPORT_PARTNER = "/contactsupportpartner",
  NOTFOUND = "/notfound",
  EMPTY = "",
  PASSING_AWAY = "/passingaway"
}
/* cspell: enable */

export const pageLinks: PageLink[] = [
  {
    active: true,
    href: HREF.HOME,
    index: 1,
    title: <FormattedMessage id="app.homepage" />,
  },
  {
    href: HREF.ACTIONS,
    index: 3,
    title: <FormattedMessage id="app.actions" />,
  },
  {
    href: HREF.DOCUMENTS,
    index: 4,
    title: <FormattedMessage id="app.documents" />,
  },
];

export const plannerPageLink: PageLink = {
  href: HREF.PLANNER,
  index: 2,
  title: <FormattedMessage id="app.planner" />,
};

export const personalButtons = [
  {
    colorVariant: "bg-yellow-pastel",
    icon: undefined,
    href: HREF.CONTACT,
    index: 1,
    title: <FormattedMessage id="app.contact" />,
  },
  {
    colorVariant: "bg-blue-light",
    icon: "login",
    href: HREF.MY_DATA,
    index: 2,
    title: <FormattedMessage id="app.mydata" />,
  },
  {
    colorVariant: undefined,
    icon: "logoutNavigation",
    href: HREF.LOGOUT,
    index: 3,
    title: <FormattedMessage id="app.logout" />,
  },
];
