import { RadioButtons, RadioButtonsProps } from "@fonk-gitlab/bpfd-portal";
import { ReactNode } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { YesNoValueEnum } from "../../lib/types";

interface YesNoRadioOption {
  label: ReactNode;
  checked: boolean;
  value: YesNoValueEnum;
  id: string;
}

export type YesNoRadioOptions = [YesNoRadioOption, YesNoRadioOption];

export interface YesNoProps<T extends FieldValues>
  extends Omit<RadioButtonsProps, "options">,
    UseControllerProps<T> {
  defaultChecked?: { yes: boolean; no: boolean };
  options?: YesNoRadioOptions;
}

const YesNoRadio = <T extends FieldValues>({
  name,
  options,
  defaultChecked = { yes: false, no: false },
  ...rest
}: YesNoProps<T>) => {
  const yesNoOptions = (prefix: string, defaultChecked: { yes: boolean; no: boolean }): YesNoRadioOptions => [
    {
      label: <FormattedMessage id="utils.yes" />,
      checked: defaultChecked.yes,
      value: YesNoValueEnum.YES,
      id: `${prefix}-yes`,
    },
    {
      label: <FormattedMessage id="utils.no" />,
      checked: defaultChecked.no,
      value: YesNoValueEnum.NO,
      id: `${prefix}-no`,
    },
  ];
  return <RadioButtons {...rest} name={name} options={options || yesNoOptions(name, defaultChecked)} />;
};

export default YesNoRadio;
