import { CustomDocument } from "../lib/types";
import { getAuthToken, isTokenExpired, refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../utils/token";
import { getUuid } from "../utils/uuid";

const baseUrl = window.env.VITE_API_BASE_URL;
const getHeaders = () => ({
  Authorization: "Bearer " + getAuthToken() || "",
  Accept: "application/json",
  "Content-Type": "application/json",
});

const get = async (endpoint: string, altBaseUrl?: string) => {
  const response = await fetch((altBaseUrl ?? baseUrl) + endpoint, {
    method: "GET",
    headers: getHeaders(),
  });

  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_ERROR_CODE);
  }

  if (!response.ok) {
    throw new Error("An error has occurred while processing the request.");
  }

  return await response.json();
};

const getWithRefresh = async (endpoint: string) => {
  if (isTokenExpired()) {
    await refreshAuthToken().then((response) => {
      if (response?.accessToken) {
        return get(endpoint);
      }
    });
  }
  return get(endpoint);
};

export const getUser = () => getWithRefresh(`persons/${getUuid()}`);

export const getPension = () => getWithRefresh(`persons/${getUuid()}/pensions`);

export const getCommunicationPreferences = () => getWithRefresh(`persons/${getUuid()}/preferences`);

export const getDocuments = () =>
  getWithRefresh(`persons/${getUuid()}/documents`).then((data) =>
    data.documents.sort(
      (a: CustomDocument, b: CustomDocument) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )
  );

export const getHomepageWarnings = () => get(`persons/${getUuid()}/messages`);

export const getPlanner = () => get(`persons/${getUuid()}/planner`);

export const getToggles = () => get(`persons/${getUuid()}/toggles`);

export const getSurvivors = () => get(`survivors/${getUuid()}`);

export const getCountries = () => get(`countries`);
