import { RetirementRenamed, EmploymentRenamed } from "../lib/types";

type RenamedData = RetirementRenamed | EmploymentRenamed;

const filterUnselected = (data: RenamedData) => {
  const newData: RenamedData = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const item = data[key];
      if (item.isSelected !== false) {
        newData[key] = item;
      }
    }
  }
  return newData;
};

export default filterUnselected;
