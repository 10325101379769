enum frontEndKeys {
  surrender = "surrender",
  earlyOrLater = "earlierOrLaterRetirement",
  exchange = "exchange",
  bridging = "bridging",
  highLow = "highLowLowHigh",
}

export enum apiKeys {
  surrender = "RefrainSurrender",
  earlyOrLater = "earlyOrLater",
  exchange = "Exchange",
  bridging = "Bridging",
  highLow = "HighLow",
}

interface KeyMap {
  [frontEndKeys.surrender]: apiKeys.surrender;
  [frontEndKeys.earlyOrLater]: apiKeys.earlyOrLater;
  [frontEndKeys.exchange]: apiKeys.exchange;
  [frontEndKeys.bridging]: apiKeys.bridging;
  [frontEndKeys.highLow]: apiKeys.highLow;
}

type RenameKeys<T> = {
  [K in keyof T as K extends `${infer P}Option${infer S}`
    ? `${P}${S}`
    : K extends keyof KeyMap
      ? KeyMap[K]
      : K]: T[K] extends object ? RenameKeys<T[K]> : T[K];
};

const getFinalKey = (key: string): string => {
  switch (key) {
    case frontEndKeys.surrender:
      return apiKeys.surrender;
    case frontEndKeys.earlyOrLater:
      return apiKeys.earlyOrLater;
    case frontEndKeys.exchange:
      return apiKeys.exchange;
    case frontEndKeys.bridging:
      return apiKeys.bridging;
    case frontEndKeys.highLow:
      return apiKeys.highLow;
    default:
      return key;
  }
};

export const renameKeys = <T extends object>(obj: T): RenameKeys<T> => {
  const newObj = {} as RenameKeys<T>;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.replace(/Option(s)?/g, "");
      const finalKey = getFinalKey(newKey);
      if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[finalKey as keyof RenameKeys<T>] = renameKeys(obj[key]) as RenameKeys<T>[keyof RenameKeys<T>];
      } else {
        newObj[finalKey as keyof RenameKeys<T>] = obj[key] as unknown as RenameKeys<T>[keyof RenameKeys<T>];
      }
    }
  }
  return newObj;
};
