import { PageLayout, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { getSurvivors } from "../../react-query/queries";
import PassingAwayForm from "../forms/PassingAwayForm";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";

const PassingAway = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["survivors"],
    queryFn: getSurvivors,
  });

  const survivor = data?.survivor;

  if (isLoading) {
    return <Loading />;
  }

  if (!survivor) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      <TextHeader subText="" text="" />
      <PassingAwayForm survivor={survivor} />
    </PageLayout>
  );
};

export default PassingAway;
