import { z } from "zod";
import { PaymentOptionValue } from "../../constants/passingAway";
import { validateIBAN } from "../../utils/iban";
import { useIntlMessage } from "../useIntlMessage";

const option = z.object({ value: z.string(), checked: z.boolean() });

export const useBankAccountSchema = () => {
  const intlMessage = useIntlMessage();

  function getSchema() {
    return z
      .object({
        paymentOptions: z.array(option).nullable(),
        bankNumber: z.string(),
        bankName: z.string().optional(),
        bankCode: z.string().optional(),
        bankAddress: z.string().optional(),
        bankLocation: z.string().optional(),
        bankLand: z.object({ value: z.string() }).optional(),
      })
      .refine(
        (bankAccount) => {
          const isInsideEU =
            bankAccount.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
          if (!isInsideEU) return true;
          const isValidIBAN = validateIBAN(bankAccount.bankNumber);
          return isValidIBAN;
        },
        {
          message: intlMessage("validation.bankNumber.invalid"),
          path: ["bankNumber"],
        }
      )
      .superRefine((bankAccount, ctx) => {
        const isOutsideEU =
          bankAccount.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.OutsideEU;

        if (!isOutsideEU) return;

        if (!bankAccount?.bankName) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankName"],
          });
        }
        if (!bankAccount?.bankCode) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankCode"],
          });
        }
        if (!bankAccount?.bankAddress) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankAddress"],
          });
        }
        if (!bankAccount?.bankLocation) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankLocation"],
          });
        }
        if (!bankAccount?.bankLand) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankLand"],
          });
        }
      });
  }

  return getSchema;
};
