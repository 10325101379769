import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import { ContactDetailsForm } from "../components/forms/ContactDetailsForm";
import { UserDetailsForm } from "../components/forms/UserDetailsForm";
import PartnerLogin from "../components/organisms/planner/partnerAcceptancePopup/PartnerLogin";
import Actions from "../components/pages/Actions";
import Callback from "../components/pages/Callback";
import Contact from "../components/pages/Contact";
import Documents from "../components/pages/Documents";
import Homepage from "../components/pages/Homepage";
import Login from "../components/pages/Login";
import LoginWrapper from "../components/pages/LoginWrapper";
import { Logout } from "../components/pages/Logout";
import MyData from "../components/pages/MyData";
import NotFoundPage from "../components/pages/NotFoundPage";
import PassingAway from "../components/pages/PassingAway";
import Planner from "../components/pages/Planner";
import { isLoggedIn } from "../utils/loginHandler";
import { LoggedInRoute, LoggedOutRoute } from "./ProtectedRoute";
import { HREF } from "./routes";

export const noSlashPath = (path: string) => {
  return path.substring(1);
};

const routes = [
  {
    path: HREF.HOME,
    element: (
      <LoggedInRoute>
        <App />
      </LoggedInRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: HREF.EMPTY,
        element: <Homepage />,
      },
      {
        path: HREF.PASSING_AWAY,
        element: <PassingAway />,
      },
      {
        path: noSlashPath(HREF.PLANNER),
        element: <Planner />,
      },
      {
        path: noSlashPath(HREF.ACTIONS),
        element: <Actions />,
      },
      {
        path: noSlashPath(HREF.DOCUMENTS),
        element: <Documents />,
      },
      {
        path: noSlashPath(HREF.CONTACT),
        element: <Contact />,
      },
      {
        path: noSlashPath(HREF.MY_DATA),
        element: <MyData />,
      },
      {
        path: noSlashPath(HREF.CONTACT_DETAILS_FORM),
        element: <ContactDetailsForm />,
      },
      {
        path: noSlashPath(HREF.USER_DETAILS_FORM),
        element: <UserDetailsForm />,
      },
    ],
  },
  {
    path: noSlashPath(HREF.LOGIN),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Login />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.CALLBACK),
    element: (
      <LoggedOutRoute>
        <Callback />
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.CONTACT_SUPPORT),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Contact />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: noSlashPath(HREF.LOGOUT),
    element: (
      <LoggedInRoute>
        <Logout />
      </LoggedInRoute>
    ),
  },
  {
    path: noSlashPath(HREF.NOTFOUND),
    element: (
      <LoggedInRoute>
        <NotFoundPage />
      </LoggedInRoute>
    ),
  },
  {
    path: noSlashPath(HREF.LOGIN_PARTNER),
    element: (
      <LoginWrapper>
        <PartnerLogin />
      </LoginWrapper>
    ),
  },
  {
    path: noSlashPath(HREF.CONTACT_SUPPORT_PARTNER),
    element: (
      <LoginWrapper>
        <Contact isPartner />
      </LoginWrapper>
    ),
  },

  {
    path: "*",
    element: <Navigate to={!isLoggedIn() ? HREF.LOGIN : HREF.NOTFOUND} replace />,
  },
];

export const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});
