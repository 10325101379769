import {
  FormField,
  FormPopup,
  FormTextBlock,
  Multistep,
  MultistepButtonCollection,
  MultistepPanel,
  RadioButtons,
} from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PaymentOptionValue } from "../../../constants/passingAway";
import { usePlanner } from "../../../context/PlannerContext";
import {
  TPlannerAdditionalQuestSchema,
  usePlannerAdditionalQuestSchema,
} from "../../../hooks/schemas/plannerAdditionalQuestSchema";
import useCountries from "../../../hooks/useCountries";
import { PlannerSaveConfirmChoices, YesNoValueEnum } from "../../../lib/types";
import { HREF } from "../../../routes/routes";
import { retiringSoonResourceLink } from "../../../utils/pensionLinks";
import { getUuid } from "../../../utils/uuid";
import BankAccountForm from "../../forms/BankAccountForm";

interface AdditionalQuestionsProps {
  showPopup: boolean;
  onClose: (payload?: PlannerSaveConfirmChoices) => void;
}

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({ showPopup, onClose }) => {
  const { relation, person, employment } = usePlanner()?.planner ?? {};
  const { countriesByUserLng } = useCountries();
  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));

  const bankAccountTypes = [
    {
      label: <FormattedMessage id="passing-away.within-eu" />,
      checked: person?.personalBankAccount?.isBankAccountEU ?? false,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: <FormattedMessage id="passing-away.outside-eu" />,
      checked: !person?.personalBankAccount?.isBankAccountEU,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const yesNoOptions = (prefix: string, defaultChecked: boolean) => {
    return [
      {
        label: <FormattedMessage id="utils.yes" />,
        checked: defaultChecked,
        value: YesNoValueEnum.YES,
        id: `${prefix}-yes`,
      },
      {
        label: <FormattedMessage id="utils.no" />,
        checked: !defaultChecked,
        value: YesNoValueEnum.NO,
        id: `${prefix}-no`,
      },
    ];
  };

  const havingPartnerOptions = yesNoOptions("partner", !!relation?.partnerId);
  const taxReductionOptions = yesNoOptions("tax", !!person?.hasTaxException);
  const disabledOptions = yesNoOptions("disabled", !!person?.disabled);

  const existingCountryCode = person?.personalBankAccount?.countryCode ?? 0;
  const existingBankLand = !person?.personalBankAccount?.isBankAccountEU
    ? countriesOptions.find((country) => country.value === existingCountryCode.toString())
    : undefined;

  const plannerAdditionalQuestSchema = usePlannerAdditionalQuestSchema();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<TPlannerAdditionalQuestSchema>({
    mode: "onChange",
    defaultValues: {
      bankAccount: {
        paymentOptions: bankAccountTypes,
        bankNumber: person?.personalBankAccount?.bankAccount ?? "",
        bankName: person?.personalBankAccount?.bankName ?? "",
        bankCode: person?.personalBankAccount?.bankBicCode ?? "",
        bankAddress: person?.personalBankAccount?.bankAddress ?? "",
        bankLocation: person?.personalBankAccount?.locationBank ?? "",
        bankLand: existingBankLand,
      },
      havingPartnerOptions,
      taxReductionOptions,
      disabledOptions,
    },
    resolver: zodResolver(plannerAdditionalQuestSchema),
  });

  const [watchBankAccountTypes, watchPartnerOptions, watchDisabledOptions] = watch([
    "bankAccount.paymentOptions",
    "havingPartnerOptions",
    "disabledOptions",
  ]);

  const isInsideEU =
    !!person?.personalBankAccount?.isBankAccountEU ||
    watchBankAccountTypes?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
  const showCohabitationLink =
    watchPartnerOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const disabledYes = watchDisabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

  const [currentStep, setCurrentStep] = useState(1);
  const handleStepClick = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (existingBankLand) {
      setValue("bankAccount.bankLand", existingBankLand);
    }
  }, [existingBankLand, setValue]);

  const onSubmit = (data: TPlannerAdditionalQuestSchema) => {
    const selectedBankAccountType =
      data?.bankAccount?.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
    const selectedTaxOption =
      data?.taxReductionOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
    const selectedCountry = data?.bankAccount?.bankLand?.value;
    const additionalPayload: PlannerSaveConfirmChoices = {
      retirementChoice: {
        bankAccount: {
          isBankAccountEU: selectedBankAccountType,
          bankAccountNumber: data?.bankAccount?.bankNumber,
          bankName: data?.bankAccount?.bankName,
          bankBicCode: data?.bankAccount?.bankCode,
          bankAddress: data?.bankAccount?.bankAddress,
          bankLocation: data?.bankAccount?.bankLocation,
          bankCountryName: selectedCountry,
        },
        personId: getUuid() ?? "",
        taxExemption: selectedTaxOption,
        partnerId: relation?.partnerId ?? null,
      },
    };
    onClose?.(additionalPayload);
  };

  return (
    <FormPopup open={showPopup} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Multistep
          steps={[
            { label: <FormattedMessage id="planner.complete-quest.intro" />, index: 1 },
            { label: "Question 1", index: 2 },
            { label: "Question 2", index: 3 },
            { label: "Question 3", index: 4 },
            { label: "Question 4", index: 5 },
          ]}
          interactive
          onStepClick={handleStepClick}
          currentStep={currentStep}>
          <MultistepPanel tabIndex={1}>
            <FormTextBlock
              body={<FormattedMessage id="planner.complete-quest.popup-intro" />}
              heading={<FormattedMessage id="planner.complete-quest.popup-heading" />}
            />
          </MultistepPanel>
          <MultistepPanel tabIndex={2}>
            <FormField
              id="havingPartnerOptions"
              labelText={<FormattedMessage id="planner.complete-quest.partner-question" />}>
              <RadioButtons name="havingPartnerOptions" options={havingPartnerOptions} control={control} />
            </FormField>
            {showCohabitationLink && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.register-partners"
                    values={{
                      registerLink: (
                        <Link to={HREF.MY_DATA} target="_blank">
                          <FormattedMessage id="planner.complete-quest.alt-text" />
                        </Link>
                      ),
                    }}
                  />
                }
              />
            )}
          </MultistepPanel>
          <MultistepPanel tabIndex={3}>
            <BankAccountForm
              name="bankAccount"
              control={control as unknown as Control<FieldValues>}
              isInsideEU={isInsideEU}
            />
          </MultistepPanel>
          <MultistepPanel tabIndex={4}>
            <FormField
              id="taxReductionOptions"
              labelText={<FormattedMessage id="planner.complete-quest.tax-question" />}>
              <RadioButtons name="taxReductionOptions" options={taxReductionOptions} control={control} />
            </FormField>
            <FormTextBlock body={<FormattedMessage id="planner.complete-quest.eligible-tax" />} />
            <FormTextBlock
              body={
                <FormattedMessage
                  id="planner.complete-quest.tax-reduction-info"
                  values={{
                    link: (
                      <Link to={retiringSoonResourceLink} target="_blank">
                        <FormattedMessage id="planner.exchange.altText-link" />
                      </Link>
                    ),
                  }}
                />
              }
            />
            <FormTextBlock body={<FormattedMessage id="planner.complete-quest.eligible-tax-info" />} />
          </MultistepPanel>
          <MultistepPanel tabIndex={5}>
            <FormField
              id="disabledOptions"
              labelText={<FormattedMessage id="planner.complete-quest.disability-question" />}>
              <RadioButtons name="disabledOptions" options={disabledOptions} control={control} />
            </FormField>
            {disabledYes && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.contact-info"
                    values={{ phoneNumber: employment?.providerParticipantPhoneNumber }}
                  />
                }
              />
            )}
            <FormTextBlock
              body={<FormattedMessage id="planner.complete-quest.disability-info" values={{ br: <br /> }} />}
            />
          </MultistepPanel>
          <MultistepButtonCollection
            buttons={{
              backButton: {
                onClick: () => {
                  setCurrentStep((prev) => prev - 1);
                },
                text: "Back",
                type: "button",
              },
              forwardButton: {
                onClick: () => {
                  setCurrentStep((prev) => prev + 1);
                },
                text: "Next",
                type: "button",
              },
              startButton: {
                onClick: () => {
                  setCurrentStep((prev) => prev + 1);
                },
                text: "Start",
                type: "button",
              },
              completeButton: {
                text: <FormattedMessage id="planner.complete-quest.continue-to-sign" />,
                type: "submit",
                disabled: !isValid || isSubmitting,
              },
            }}
          />
        </Multistep>
      </form>
    </FormPopup>
  );
};

export default AdditionalQuestions;
