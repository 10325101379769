import { DataTableOverview, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { getDocuments } from "../../react-query/queries";
import { downloadDocument } from "../../react-query/mutations";
import { FormattedMessage } from "react-intl";
import Loading from "../organisms/Loading";
import { CustomDocument } from "../../lib/types";
import { useHandleAuthError } from "../organisms/ErrorHandlingHelper";

const storageAccount = window.env.VITE_STORAGE_ACCOUNT;

export const Documents = () => {
  const {
    data: documents,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["documents"],
    queryFn: getDocuments,
  });

  useHandleAuthError(error, refetch);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex-grow">
      <TextHeader
        text={<FormattedMessage id="app.documents" />}
        subText={!documents ? <FormattedMessage id="my-documents.not-found" /> : ""}
      />
      {documents && (
        <DataTableOverview
          dataItems={documents.map((document: CustomDocument, index: number) => {
            return {
              id: index,
              name: document.name,
              date: document.createdDate,
              hrefOnClick: () => {
                downloadDocument({
                  fileName: document.storageName,
                  container: document.container,
                  storageAccount: storageAccount,
                });
              },
            };
          })}
          dateFormat="dd-mm-yyyy"
          heading="Status verzoeken"
          searchInputName="search-input-name"
          searchInputPlaceholder="Zoek document"
          tableHeader={[
            {
              index: 0,
              title: <FormattedMessage id="utils.date" />,
            },
            {
              index: 1,
              title: <FormattedMessage id="utils.name" />,
            }
          ]}
          variation="documents"
        />
      )}
    </div>
  );
};

export default Documents;
