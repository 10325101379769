import { Situation } from "../lib/types";

export const formatSituationToAPI = (situations?: Situation[]) => {
  if (!situations) return [];
  return situations.map(
    ({
      totalCalculatedGross: _totalCalculatedGross,
      totalCalculatedNet: _totalCalculatedNet,
      totalCurrentNet: _totalCurrentNet,
      ...keepAttrs
    }) => {
      return {
        ...keepAttrs,
        amounts: keepAttrs.amounts.map(
          ({ info: _info, isTotal: _isTotal, referenceValue: _referenceValue, ...keepAttrs }) => {
            return keepAttrs;
          }
        ),
      };
    }
  );
};

export default formatSituationToAPI;
