import { EmploymentPayload, EmploymentRenamed } from "../lib/types";
import filterUnselected from "./filterUnselected";

const formatEmploymentToAPI = (employment?: EmploymentRenamed): EmploymentPayload => {
  if (!employment) {
    return {};
  }
  const newEmployment = { ...employment };
  for (const key in newEmployment) {
    if (Object.prototype.hasOwnProperty.call(newEmployment, key) && typeof newEmployment[key] === "object") {
      const {
        ageMonth,
        ageYear,
        calendarYear: _calendarYear,
        calendarMonth: _calendarMonth,
        ...rest
      } = newEmployment[key];
      newEmployment[key] = {
        ...rest,
        ...(ageMonth !== undefined && { months: ageMonth }),
        ...(ageYear !== undefined && { years: ageYear }),
      };
    }
  }

  return filterUnselected(newEmployment);
};

export default formatEmploymentToAPI;
