// format date follow pattern yyyy-MM-dd
export const formatDate = (target: Date) => {
  if (typeof target.getMonth !== "function") throw new Error("Target date is not date!");
  const year = target.getFullYear();
  let month: number | string = target.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let date: number | string = target.getDate();
  if (date < 10) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
};
