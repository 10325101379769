import { useEffect, useState } from "react";
import { LoginPanel, Button, Link } from "@fonk-gitlab/bpfd-portal";
import { HREF } from "../../../../routes/routes";
import { REDIRECT_URLS, RedirectUrls } from "../../../../hooks/useExternalWindow";
import {
  CANNOT_CONNECT,
  EUROPEES_INLOGGEN,
  INLOGGEN_MET_DIGID,
  LOGIN_WELCOME,
} from "../../../../lib/constants";

const PartnerLogin = () => {
  const [redirectUrls, setRedirectUrls] = useState<RedirectUrls | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState(false);

  const currentUrl = window.location.href;

  useEffect(() => {
    if (redirectUrls === undefined) {
      // Retrieve redirectUrls from local storage
      const storedRedirectUrls = localStorage.getItem(REDIRECT_URLS);
      if (storedRedirectUrls) {
        const parsedRedirectUrls: RedirectUrls = JSON.parse(storedRedirectUrls);
        setRedirectUrls(parsedRedirectUrls);
      }
    }

    if (currentUrl.includes("data")) {
      setIsProcessing(true);
    }
  }, [currentUrl, redirectUrls]);

  return (
    <div className="container py-20 mx-auto" style={{ width: `70%` }}>
      {!isProcessing && (
        <LoginPanel
          heading={LOGIN_WELCOME}
          buttons={
            <>
              {redirectUrls?.DIGID && (
                <Button
                  logo="digid"
                  size="md"
                  text={INLOGGEN_MET_DIGID}
                  onClick={() => (window.location.href = redirectUrls?.DIGID ?? "")}
                />
              )}
              {redirectUrls?.EIDAS && (
                <Button
                  logo="eidas"
                  size="md"
                  text={EUROPEES_INLOGGEN}
                  onClick={() => (window.location.href = redirectUrls?.EIDAS ?? "")}
                />
              )}
            </>
          }
          link={<Link href={HREF.CONTACT_SUPPORT_PARTNER} text={CANNOT_CONNECT} />}
        />
      )}
    </div>
  );
};

export default PartnerLogin;
