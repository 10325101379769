import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

const useTrackEarlierOrLaterOption = () => {
  const { getValues, watch } = useFormContext();
  const fullyOrPartialKey = "retirementOption.earlierOrLaterRetirementOptions.fullyOrPartial";
  const partialAgeYearKey = "retirementOption.earlierOrLaterRetirementOptions.partialAgeYear";
  const partialAgeMonthKey = "retirementOption.earlierOrLaterRetirementOptions.partialAgeMonth";
  const fullAgeYearKey = "retirementOption.earlierOrLaterRetirementOptions.fullAgeYear";
  const fullAgeMonthKey = "retirementOption.earlierOrLaterRetirementOptions.fullAgeMonth";

  const [earlierLaterOptionChanges, setEarlierLaterOptionChanges] = useState(false);
  const [fullyOrPartial, setFullyOrPartial] = useState(getValues(fullyOrPartialKey)?.value);
  const [partialAgeYear, setPartialAgeYear] = useState(getValues(partialAgeYearKey));
  const [partialAgeMonth, setPartialAgeMonth] = useState(getValues(partialAgeMonthKey));
  const [fullAgeYear, setFullAgeYear] = useState(getValues(fullAgeYearKey));
  const [fullAgeMonth, setFullAgeMonth] = useState(getValues(fullAgeMonthKey));

  const [
    watchFullyOrPartial,
    watchPartialAgeYear,
    watchPartialAgeMonth,
    watchFullAgeYear,
    watchFullAgeMonth,
  ] = watch([fullyOrPartialKey, partialAgeYearKey, partialAgeMonthKey, fullAgeYearKey, fullAgeMonthKey]);
  const watchFullyOrPartialValue = watchFullyOrPartial?.value;

  const isFieldsChanges =
    watchFullyOrPartialValue === fullyOrPartial &&
    watchPartialAgeYear === partialAgeYear &&
    watchPartialAgeMonth === partialAgeMonth &&
    watchFullAgeYear === fullAgeYear &&
    watchFullAgeMonth === fullAgeMonth;

  useEffect(() => {
    if (watchFullyOrPartialValue === fullyOrPartial) return;
    setFullyOrPartial(watchFullyOrPartialValue);
  }, [watchFullyOrPartialValue, fullyOrPartial, setFullyOrPartial]);

  useEffect(() => {
    if (watchPartialAgeYear === partialAgeYear) return;
    setPartialAgeYear(watchPartialAgeYear);
  }, [watchPartialAgeYear, partialAgeYear, setPartialAgeYear]);

  useEffect(() => {
    if (watchPartialAgeMonth === partialAgeMonth) return;
    setPartialAgeMonth(watchPartialAgeMonth);
  }, [watchPartialAgeMonth, partialAgeMonth, setPartialAgeMonth]);

  useEffect(() => {
    if (watchFullAgeYear === fullAgeYear) return;
    setFullAgeYear(watchFullAgeYear);
  }, [watchFullAgeYear, fullAgeYear, setFullAgeYear]);

  useEffect(() => {
    if (watchFullAgeMonth === fullAgeMonth) return;
    setFullAgeMonth(watchFullAgeMonth);
  }, [watchFullAgeMonth, fullAgeMonth, setFullAgeMonth]);

  useEffect(() => {
    if (isFieldsChanges) return;
    setEarlierLaterOptionChanges(true);
  }, [setEarlierLaterOptionChanges, isFieldsChanges]);

  return earlierLaterOptionChanges;
};

export default useTrackEarlierOrLaterOption;
