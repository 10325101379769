import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, PageLayout, Popup, TextHeader } from "@fonk-gitlab/bpfd-portal";
import PlannerSituation from "../organisms/planner/PlannerSituation";
import PlannerPredictionHeader from "../organisms/planner/PlannerPredictionHeader";
import PlannerOptions from "../organisms/planner/PlannerOptions";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { filterUnusedAttributes, PlannerProvider, usePlanner } from "../../context/PlannerContext";
import {
  EmploymentAndRetirementRenamed,
  ParticipationStatus,
  PlannerCalculatePayload,
} from "../../lib/types";
import formatEmploymentToAPI from "../../utils/formatEmploymentToAPI";
import formatRetirementPayload from "../../utils/formatRetirementToAPI";
import formatSituationPayload from "../../utils/formatSituationToAPI";
import { renameKeys } from "../../utils/renameKeys";
import formatScenarioToForm from "../../utils/formatScenarioToForm";

const PlannerConsumer: React.FC = () => {
  const intl = useIntl();
  const {
    planner,
    isLoading,
    calculate: retrieve,
    isPending,
    isSuccess,
    deleteScenarioAsync,
  } = usePlanner() || {};

  const [openPopup, setOpenPopup] = useState<boolean>();
  const [isRetrieved, setIsRetrieved] = useState<boolean>(false);

  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const birthDay = planner?.person?.dateOfBirth;

  useEffect(() => {
    if (planner?.scenario && openPopup === undefined) {
      setOpenPopup(true);
    }
  }, [openPopup, planner]);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleRetrieve = () => {
    if (planner?.scenario && retrieve) {
      const filteredScenario = filterUnusedAttributes(planner.scenario);
      if (filteredScenario) {
        const formattedData = formatScenarioToForm(filteredScenario, intl);
        // map from scenario to submitData
        const renamedKeys = renameKeys(formattedData) as unknown as EmploymentAndRetirementRenamed;
        const payload: PlannerCalculatePayload = {
          hours: Number(planner?.employment.hours),
          salary: Number(planner?.employment.salary),
          situations: formatSituationPayload(planner?.situations),
          employment: formatEmploymentToAPI(renamedKeys.employment),
          retirement: formatRetirementPayload(renamedKeys.retirement),
        };
        setIsRetrieved(true);
        retrieve(payload);
      }
    }
  };

  const handleDelete = () => {
    if (deleteScenarioAsync) {
      setIsRetrieved(false);
      deleteScenarioAsync();
    }
    handleClosePopup();
  };

  useEffect(() => {
    if (isSuccess && openPopup) {
      handleClosePopup();
    }
  }, [isSuccess, openPopup]);

  if (isLoading) {
    return <Loading />;
  }

  if (!planner || !birthDay) {
    return <RequestError />;
  }

  return (
    <>
      <PageLayout>
        <Fragment key=".1">
          <TextHeader subText={<FormattedMessage id="planner.description" />} text="" />
          <div className="container py-20 flex-grow">
            <PlannerPredictionHeader isActive={isActive} employment={planner.employment} />
            <div className="grid grid-cols-12 gap-x-1 md:gap-x-2">
              <div className="lg:col-span-5 col-span-12 lg:col-start-2 order-2 lg-order-1 mb-20 lg:mb-0">
                <PlannerOptions isRetrieved={isRetrieved} />
              </div>
              <div className="col-span-12 lg:col-span-5 order-1 lg:order-2 mb-20 lg:mb-0">
                <PlannerSituation />
              </div>
            </div>
          </div>
        </Fragment>
      </PageLayout>
      <Popup
        open={openPopup}
        body={<FormattedMessage id="planner.use-scenario" />}
        buttons={
          <>
            <Button
              ariaLabel="yes"
              size="md"
              text={<FormattedMessage id="utils.yes" />}
              onClick={handleRetrieve}
              disabled={isPending}
            />
            <Button
              ariaLabel="no"
              size="md"
              text={<FormattedMessage id="utils.no" />}
              onClick={handleDelete}
              disabled={isPending}
            />
          </>
        }
        onClose={handleClosePopup}
        popupVariant="confirmation"
      />
    </>
  );
};

const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerConsumer />
    </PlannerProvider>
  );
};

export default Planner;
