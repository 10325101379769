import { FormField, FormImportantMessage, Input } from "@fonk-gitlab/bpfd-portal";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import { usePlanner } from "../../../context/PlannerContext";
import { ageToCalendarCalculation, calendarToAgeCalculation } from "../../../utils/ageCalendarConverter";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";

const EarningSalaryOption = () => {
  const planner = usePlanner()?.planner;
  const intl = useIntl();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const salaryKey = "employmentOptions.earningSalaryOption.salary";
  const ageYearKey = "employmentOptions.earningSalaryOption.ageYear";
  const ageMonthKey = "employmentOptions.earningSalaryOption.ageMonth";
  const calendarYearKey = "employmentOptions.earningSalaryOption.calendarYear";
  const calendarMonthKey = "employmentOptions.earningSalaryOption.calendarMonth";

  const [watchedSalary, watchedAgeYear, watchedAgeMonth, watchedCalendarYear, watchedCalendarMonth] = watch([
    salaryKey,
    ageYearKey,
    ageMonthKey,
    calendarYearKey,
    calendarMonthKey,
  ]);

  const isDisplayWarning = !watchedSalary || !watchedAgeYear;
  const dateOfBirth = planner?.person?.dateOfBirth ?? "";

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedAgeYear), month: Number(watchedAgeMonth ?? 0) },
      { yearKey: calendarYearKey, monthKey: calendarMonthKey },
      { getValues, setValue }
    );
  }, [watchedAgeMonth, watchedAgeYear, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedCalendarYear), month: Number(watchedCalendarMonth?.value) },
      { yearKey: ageYearKey, monthKey: ageMonthKey },
      { getValues, setValue }
    );
  }, [watchedCalendarMonth, watchedCalendarYear, dateOfBirth, getValues, setValue]);

  return (
    <>
      {isDisplayWarning && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.warning.required-fields" />}
          className="col-span-12 mb-15"
        />
      )}
      <FormField
        className="col-span-12"
        id={salaryKey}
        labelText={<FormattedMessage id="planner.earning-salary-label" />}>
        <Input
          id={salaryKey}
          name={salaryKey}
          placeholder={intl.formatMessage({ id: "planner.earning-salary-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <div className="col-span-12 text-form text-black font-semibold">
        <span>
          <FormattedMessage id="planner.from-what" />
        </span>
      </div>
      <FormField
        className="col-span-6"
        id={ageYearKey}
        labelText={<FormattedMessage id="planner.year-label" />}>
        <Input
          id={ageYearKey}
          name={ageYearKey}
          placeholder={intl.formatMessage({ id: "planner.year-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <FormField
        className="col-span-6"
        id={ageMonthKey}
        labelText={<FormattedMessage id="planner.month-label" />}>
        <Input
          id={ageMonthKey}
          name={ageMonthKey}
          placeholder={intl.formatMessage({ id: "planner.month-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <div className="col-span-12 text-form text-black font-semibold">
        <span>Per</span>
      </div>
      <PlannerMonthDropdown
        inputName={calendarMonthKey}
        placeholder={intl.formatMessage({ id: "planner.select-month" })}
        control={control}
      />
      <FormField
        className="col-span-6"
        id={calendarYearKey}
        labelText={<FormattedMessage id="planner.year-label" />}>
        <Input
          id={calendarYearKey}
          name={calendarYearKey}
          placeholder={intl.formatMessage({ id: "planner.select-year" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
    </>
  );
};

export default EarningSalaryOption;
