import { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { Header, PageLayout } from "@fonk-gitlab/bpfd-portal";
import { useNavigate } from "react-router-dom";
import { HREF } from "../../routes/routes";
import { PortalLanguage } from "../../utils/languageLoader";
import { messages } from "../../lib/messages";

interface LoginWrapperProps {
  children: ReactNode;
}

const LoginWrapper = ({ children }: LoginWrapperProps) => {
  const navigate = useNavigate();

  const noop = () => {
    return;
  }; // No-op function, avoid using unless necessary

  return (
    <IntlProvider locale={PortalLanguage.NL} messages={messages[PortalLanguage.NL]}>
      <PageLayout>
        <div className="bg-gradient-mint-blue-light flex flex-col min-h-screen">
          <Header
            clickLogo={() => navigate(HREF.HOME)}
            headerButtonHandler={noop}
            headerButtonsData={[]}
            hideMobileMenu
            links={[]}
            isNavOpen={false}
            onNavClick={noop}
          />
          {/* Child component */}
          {children}
        </div>
      </PageLayout>
    </IntlProvider>
  );
};

export default LoginWrapper;
