import { ReactNode, useMemo, useContext, createContext, useState, useEffect } from "react";
import { Planner, PlannerCalculatePayload, Scenario, UnfilteredScenario } from "../lib/types";
import { UseMutateAsyncFunction, useMutation, useQuery } from "@tanstack/react-query";
import { getPlanner } from "../react-query/queries";
import { useHandleAuthError } from "../components/organisms/ErrorHandlingHelper";
import { calculatePlanner, deleteScenario } from "../react-query/mutations";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../utils/token";

interface PlannerContextProps {
  planner: Planner | undefined;
  isLoading: boolean;
  calculate: (data: PlannerCalculatePayload) => Promise<void>;
  isPending: boolean;
  isSuccess: boolean;
  errorCalculate: Error | null;
  situations: Planner["situations"];
  scenario: Scenario | null;
  deleteScenarioAsync: UseMutateAsyncFunction;
}

interface PlannerProviderProps {
  children: ReactNode;
}

export const filterUnusedAttributes = (scenario: UnfilteredScenario | null): Scenario | null => {
  if (!scenario) return null;
  const {
    lumpSumAmount: _lumpSumAmount,
    isPartnerDigitalSigned: _isPartnerDigitalSigned,
    isParticipantDigitalSigned: _isParticipantDigitalSigned,
    indicationMaxLumpSum: _indicationMaxLumpSum,
    indicationEndEmployment: _indicationEndEmployment,
    indicationSurrender: _indicationSurrender,
    dateLumpSum: _dateLumpSum,
    currentExternalContractHours: _currentExternalContractHours,
    currentExternalSalary: _currentExternalSalary,
    ...rest
  } = scenario;

  return rest;
};

export const PlannerContext = createContext<PlannerContextProps | undefined>(undefined);

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  // planner situations array is only used for default values
  // actual situations will come from calculate responses.
  const {
    data: planner,
    error: errorPlanner,
    isLoading,
    refetch: refetchPlanner,
  } = useQuery({
    queryKey: ["planner"],
    queryFn: getPlanner,
    select: (data) => data.planner,
  });

  const [situations, setSituations] = useState<Planner["situations"]>(planner?.situations || []);
  const [scenario, setScenario] = useState<Scenario | null>(null);

  useEffect(() => {
    if (planner?.situations && situations.length === 0) {
      setSituations(planner.situations);
    }
  }, [situations.length, planner]);

  useEffect(() => {
    if (planner?.scenario && scenario === null) {
      setScenario(filterUnusedAttributes(planner.scenario));
    }
  }, [scenario, planner]);

  const {
    mutateAsync: calculate,
    isPending,
    isSuccess,
    error: errorCalculate,
  } = useMutation({
    mutationFn: calculatePlanner,
    onSuccess: (res) => {
      setSituations(res.planner.situations);
      setScenario(filterUnusedAttributes(res.planner.scenario));
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return calculate(data);
          }
        });
      }
    },
  });

  const { mutateAsync: deleteScenarioAsync } = useMutation({
    mutationFn: deleteScenario,
    onError: async (postError): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) return deleteScenarioAsync();
        });
      }
      console.log(postError?.message);
    },
  });

  useHandleAuthError(errorPlanner, refetchPlanner);

  const contextValue = useMemo(
    () => ({
      planner,
      isLoading,
      calculate,
      isPending,
      isSuccess,
      errorCalculate,
      situations,
      scenario,
      deleteScenarioAsync,
    }),
    [
      calculate,
      errorCalculate,
      isLoading,
      isPending,
      isSuccess,
      planner,
      scenario,
      situations,
      deleteScenarioAsync,
    ]
  );

  return <PlannerContext.Provider value={contextValue}>{children}</PlannerContext.Provider>;
};

export const usePlanner = (): PlannerContextProps | undefined => {
  const context = useContext(PlannerContext);
  return context;
};
