import { InformationPanel, Link } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { HREF } from "../../routes/routes";

interface Props {
  isPartner?: boolean;
}

const Contact: React.FC<Props> = ({ isPartner }) => {
  return (
    <div className="py-20 flex-grow">
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
          <InformationPanel
            header="Contact"
            informationDetails={[
              {
                label: <FormattedMessage id="utils.email" />,
                input: "deelnemer@pensioenfondsdetailhandel.nl",
              },
              {
                label: <FormattedMessage id="utils.phone" />,
                input: "0800-1972",
              },
              {
                label: <FormattedMessage id="utils.phone-abroad" />,
                input: "(+31) 20 - 244 54 01",
              },
              {
                label: <FormattedMessage id="utils.post-address" />,
                input: (
                  <>
                    Postbus 59205
                    <br />
                    1040 KE Amsterdam
                  </>
                ),
              },
            ]}
            buttons={
              isPartner && (
                <Link href={HREF.LOGIN_PARTNER} text={<FormattedMessage id="planner.partner-login-back" />} />
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
