import { nanoid } from "nanoid";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { LoginPanel, Button, TabCollection, Link, FormImportantMessage } from "@fonk-gitlab/bpfd-portal";
import { HREF } from "../../routes/routes";
import {
  CANNOT_CONNECT,
  EUROPEES_INLOGGEN,
  INLOGGEN_MET_DIGID,
  LOGIN_WARNING,
  LOGIN_WELCOME,
} from "../../lib/constants";

interface LocationState {
  error?: string;
}

const Login = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [activeKey, setActiveKey] = useState(0);

  const handleSetActiveKey = (key: number) => {
    setActiveKey(key);
  };

  function navigateToLogin(scope: string) {
    const baseUrl = window.env.VITE_SIGNICAT_AUTHENTICATION_ENDPOINT;
    const clientId = window.env.VITE_SIGNICAT_CLIENT_ID;
    const responseType = "code";
    const redirectUri = window.env.VITE_AUTH_BASE_URL + "callback";
    const state = window.env.VITE_FRONTEND_REDIRECT_STATE + nanoid();
    const nonce = nanoid();

    const uri = `${baseUrl}?scope=${scope}&response_type=${responseType}&redirect_uri=${redirectUri}&state=${state}&nonce=${nonce}&client_id=${clientId}&response_mode=query`;

    localStorage.setItem("state", state);

    window.location.href = uri;
  }

  return (
    <div className="container py-20 mx-auto" style={{ width: `70%` }}>
      <div className="pb-5">
        {state?.error && (
          <FormImportantMessage
            body={<FormattedMessage id={state?.error} />}
            heading={LOGIN_WARNING}
            className="flex w-full pb-4"
          />
        )}
      </div>
      <LoginPanel
        heading={LOGIN_WELCOME}
        buttons={
          <>
            <Button
              logo="digid"
              size="md"
              text={INLOGGEN_MET_DIGID}
              onClick={() => navigateToLogin(window.env.VITE_SIGNICAT_DIGID_SCOPE)}
            />
            <Button
              logo="eidas"
              size="md"
              text={EUROPEES_INLOGGEN}
              onClick={() => navigateToLogin(window.env.VITE_SIGNICAT_EIDAS_SCOPE)}
            />
          </>
        }
        link={<Link href={HREF.CONTACT_SUPPORT} text={CANNOT_CONNECT} />}
        tabCollection={
          <TabCollection
            activeKey={activeKey}
            colorVariant="mint"
            contents={["Werknemer", "Gepensioneerde"]}
            handleSetActiveKey={handleSetActiveKey}
          />
        }
      />
    </div>
  );
};

export default Login;
