import { Icon, Text } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";

export const ThankYouMessage: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        minWidth: "8rem",
      }}>
      <Text
        as="span"
        fontFamily="primary"
        fontStyle="normal"
        size="base"
        type="primary-lg-button"
        weight="normal"
        className="flex flex-row gap-x-2 items-center">
        <Icon
          color="fill-black"
          hoverColor="hover:fill-black group-hover:fill-black"
          icon="checkmark"
          size="md"
          className="mr-2"
        />
        <FormattedMessage id="We have processed your choice" />
      </Text>
    </div>
  );
};

export default ThankYouMessage;
