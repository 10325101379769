export function formatStringWithSpaces(str: string, each = 0) {
  if (typeof str !== "string") throw new Error("Formatted target must be string");
  if (each === 0) return str;
  // Remove all spaces from the input string
  str = removeSpace(str);
  // Create a regex pattern based on the variable each
  const regex = new RegExp(`.{1,${each}}`, "g");
  // Match the pattern and join with spaces
  return str?.match(regex)?.join(" ");
}

export function removeSpace(str: string) {
  if (typeof str !== "string") throw new Error("Target must be string");
  return str.replace(/\s+/g, "");
}

export function toNumeric(str: string) {
  return typeof str === "string" ? str.replace(/\D/g, "") : "";
}

export function toAlphanumeric(str: string) {
  return typeof str === "string" ? str.replace(/[^a-zA-Z0-9]/g, "") : "";
}
