import {
  DatePicker,
  Dropdown,
  FormField,
  FormSubmit,
  IconButton,
  Input,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Control, Controller, FieldValues, useFieldArray, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react/jsx-runtime";
import { PaymentOptionValue, WZPValue } from "../../constants/passingAway";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { TPassingAwaySchema, usePassingAwaySchema } from "../../hooks/schemas/passingAwaySchema";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import useScrollTo from "../../hooks/useScrollTo";
import { Survivor, SurvivorPayload, YesNoValueEnum } from "../../lib/types";
import { updateSurvivor } from "../../react-query/mutations";
import { formatDate } from "../../utils/date";
import { removeSpace } from "../../utils/string";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../utils/token";
import YesNoRadio, { YesNoRadioOptions } from "../atoms/YesNoRadio";
import SuccessContainer from "../molecules/passingAway/SuccessContainer";
import BankAccountForm from "./BankAccountForm";

const yesNoOptions = (prefix: string, defaultChecked: { yes: boolean; no: boolean }): YesNoRadioOptions => [
  {
    label: <FormattedMessage id="utils.yes" />,
    checked: defaultChecked.yes,
    value: YesNoValueEnum.YES,
    id: `${prefix}-yes`,
  },
  {
    label: <FormattedMessage id="utils.no" />,
    checked: defaultChecked.no,
    value: YesNoValueEnum.NO,
    id: `${prefix}-no`,
  },
];

interface Props {
  survivor: Survivor;
}

const PassingAwayForm = ({ survivor }: Props) => {
  const intlMessage = useIntlMessage();
  const auth = useAuthentication();
  const userName = auth?.user?.firstName ?? "";
  const { ref, scrollToView } = useScrollTo<HTMLDivElement>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const registeredChildren = survivor.children;
  const genderItems = survivor.genders
    ? Object.keys(survivor.genders).map((key) => {
        const value = survivor.genders[key as keyof typeof survivor.genders];
        return {
          id: value,
          value: value,
          label: intlMessage(`utils.gender.${value}`),
        };
      })
    : [];
  const paymentOptions = [
    {
      label: <FormattedMessage id="passing-away.within-eu" />,
      checked: true,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: <FormattedMessage id="passing-away.outside-eu" />,
      checked: false,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];
  const wageTaxOptions = yesNoOptions("tax", { yes: survivor.isTaxCheck, no: !survivor.isTaxCheck });
  const awnOptions = yesNoOptions("awn", { yes: false, no: true });
  const childrenOptions = yesNoOptions("children", { yes: false, no: true });
  const hasBankAccount = false;
  const passingAwaySchema = usePassingAwaySchema(hasBankAccount);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TPassingAwaySchema>({
    defaultValues: {
      bankAccount: {
        paymentOptions: !hasBankAccount ? paymentOptions : null,
      },
      wageTaxOptions,
      awnOptions,
      childrenOptions,
    },
    resolver: zodResolver(passingAwaySchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "children",
  });
  const [watchedChildrenOptions, watchedPaymentOptions] = watch([
    "childrenOptions",
    "bankAccount.paymentOptions",
  ]);

  const showChildrenInputs =
    watchedChildrenOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const showAWN = survivor.checkANW;
  const isInsideEU =
    watchedPaymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
  const showBankAccountForm = !hasBankAccount;
  const hasSicknessBenefit = survivor.isSicknessBenefit;
  const {
    mutate,
    isPending,
    error: postError,
  } = useMutation({
    mutationFn: updateSurvivor,
    onSuccess: () => {
      setShowSuccess(true);
      scrollToView();
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutate(data);
          }
        });
      }
    },
  });

  const handleAddChild = () => {
    append({ firstName: "", lastName: "", dob: "" as unknown as Date, bsn: "", gender: genderItems[1] });
  };
  const handleRemoveChild = (index: number) => {
    remove(index);
  };
  const onSubmit = (values: TPassingAwaySchema) => {
    const submitData: SurvivorPayload = {
      bankAccount: {
        isBankAccountEU: isInsideEU,
        bankAccountNumber: removeSpace(
          values.bankAccount?.bankNumber ?? survivor.bankAccount?.bankAccountNumber
        ),
        bankName: values.bankAccount?.bankName,
        bankBicCode: values.bankAccount?.bankCode,
        bankAddress: values.bankAccount?.bankAddress,
        bankLocation: values.bankAccount?.bankLocation,
        bankCountryName: values.bankAccount?.bankLand?.value,
      },
      isTaxCheck: values.wageTaxOptions.find((option) => option.checked)?.value === YesNoValueEnum.YES,
      isANWCheck: values?.awnOptions?.find((option) => option.checked)?.value === YesNoValueEnum.YES,
      fosterChildren: values?.children
        ? values.children.map((child) => ({
            firstName: child.firstName,
            lastName: child.lastName,
            bsn: child.bsn,
            gender: child.gender.value,
            birthdate: formatDate(child.dob),
          }))
        : undefined,
      dateOfDeathParticipant: survivor.dateOfDeathParticipant,
      isStudent: survivor.isStudent,
      deceasedId: survivor.deceasedId,
      caseId: survivor.caseId,
      pensionAddress: survivor.pensionAddress,
      pensionEmail: survivor.pensionEmail,
      deceasedName: survivor.deceasedName,
      isAdult: survivor.isAdult,
      survivorId: survivor.survivorId,
      gender: survivor.gender,
      isOrphan: survivor.isOrphan,
      isSicknessBenefit: survivor.isSicknessBenefit,
      brpHouseNumber: survivor.brpHouseNumber,
      brpStreetName: survivor.brpStreetName,
      brpHouseNumberSuffix: survivor.brpHouseNumberSuffix,
      brpPostalCode: survivor.brpPostalCode,
      brpCityName: survivor.brpCityName,
    };
    mutate(submitData);
  };

  if (showSuccess) {
    return <SuccessContainer ref={ref} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container py-20 grid grid-cols-12">
        <div className="col-span-12 md:col-span-6 md:col-start-4">
          {hasSicknessBenefit && (
            <Text className="col-span-12 mb-10">
              <FormattedMessage id="passing-away.sickness-benefit" />
            </Text>
          )}
          {showBankAccountForm && (
            <div className="col-span-10">
              <BankAccountForm
                name="bankAccount"
                control={control as unknown as Control<FieldValues>}
                isInsideEU={isInsideEU}
              />
            </div>
          )}
          <FormField
            id="wage-tax"
            labelHeader={<FormattedMessage id="passing-away.wage-tax-title" />}
            labelText={<FormattedMessage id="passing-away.wage-tax-text" />}
            className="col-span-12">
            <Text className="mb-4">
              <FormattedMessage id="passing-away.wage-tax-question" />
            </Text>
            <YesNoRadio name="wageTaxOptions" control={control} defaultChecked={{ yes: true, no: false }} />
          </FormField>
          {showAWN && (
            <>
              <FormField
                id="awn"
                labelHeader={<FormattedMessage id="passing-away.awn-header" />}
                labelText={<FormattedMessage id="passing-away.awn-text" />}
                className="col-span-12">
                <YesNoRadio name="awnOptions" defaultChecked={{ yes: true, no: false }} control={control} />
              </FormField>
              {awnOptions.find((option) => option.checked)?.value === YesNoValueEnum.NO && (
                <div style={{ marginTop: "-32px", marginBottom: "64px" }}>
                  <Text>
                    <FormattedMessage id="passing-away.awn-no" />
                  </Text>
                </div>
              )}
            </>
          )}
          <FormField
            id="children"
            labelHeader={<FormattedMessage id="passing-away.children" />}
            className="col-span-12">
            {registeredChildren && registeredChildren.length > 0 && (
              <Fragment>
                <Text className="w-full mb-4">
                  <FormattedMessage id="passing-away.have-written" values={{ name: survivor.deceasedName }} />
                </Text>
                <table className="w-full pb-4">
                  <tr className="text-left">
                    <th className="p-2">
                      <FormattedMessage id="utils.firstName" />
                    </th>
                    <th className="p-2">
                      <FormattedMessage id="utils.lastName" />
                    </th>
                    <th className="p-2">
                      <FormattedMessage id="passing-away.awn-header" />
                    </th>
                  </tr>
                  {registeredChildren.map((item) => {
                    return (
                      <tr key={item.bsn}>
                        <td className="p-2">{item.firstName}</td>
                        <td className="p-2">{item.lastName}</td>
                        <td className="p-2">{item.birthdate}</td>
                      </tr>
                    );
                  })}
                </table>
              </Fragment>
            )}
            {(!registeredChildren || registeredChildren.length === 0) && (
              <Text>
                <FormattedMessage id="passing-away.children-no" values={{ name: survivor.deceasedName }} />
              </Text>
            )}
            <Text className="mb-4 mt-2">
              <FormattedMessage
                id="passing-away.foster-stepchildren"
                values={{
                  name: userName,
                  WZPValue: survivor.isWzp2025Apply ? WZPValue.after2025 : WZPValue.before2025,
                }}
              />
            </Text>
            <YesNoRadio
              className="col-span-12"
              name="childrenOptions"
              defaultChecked={{ yes: true, no: false }}
              control={control}
            />
            {showChildrenInputs && (
              <>
                <Text className="mb-4">
                  <FormattedMessage id="passing-away.provide-children" />
                </Text>
                {fields.map((field, index) => (
                  <div key={field.id} className="mb-10">
                    <FormField
                      id={`children.${index}.firstName`}
                      className="col-span-4"
                      labelHeader={<FormattedMessage id="utils.firstName" />}>
                      <Input
                        className="w-8"
                        id={`children.${index}.firstName`}
                        name={`children.${index}.firstName`}
                        control={control}
                      />
                    </FormField>
                    <FormField
                      id={`children.${index}.lastName`}
                      className="col-span-4"
                      labelHeader={<FormattedMessage id="utils.lastName" />}>
                      <Input
                        id={`children.${index}.lastName`}
                        name={`children.${index}.lastName`}
                        control={control}
                      />
                    </FormField>
                    <FormField
                      id={`children.${index}.dob`}
                      className="col-span-4"
                      labelHeader={<FormattedMessage id="utils.birthdate" />}>
                      <Controller
                        control={control}
                        name={`children.${index}.dob`}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            ariaLabelIconButton="Icon date picker"
                            value={value as unknown as string}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            name={`children.${index}.dob`}
                            feedbackMessage={errors?.children?.[index]?.dob?.message}
                            error={!!errors?.children?.[index]?.dob}
                            yearRange={{ from: 1900, to: new Date().getFullYear() }}
                          />
                        )}
                      />
                    </FormField>
                    <FormField
                      id={`children.${index}.bsn`}
                      className="col-span-4"
                      labelHeader={<FormattedMessage id="passing-away.children.bsn" />}>
                      <Input id={`children.${index}.bsn`} name={`children.${index}.bsn`} control={control} />
                    </FormField>
                    <FormField
                      id={`children.${index}.gender`}
                      className="col-span-4"
                      labelHeader={<FormattedMessage id="utils.gender" />}>
                      <Dropdown control={control} name={`children.${index}.gender`} items={genderItems} />
                    </FormField>
                    <IconButton variant="secondary" icon="minus" onClick={() => handleRemoveChild(index)} />
                  </div>
                ))}
                <IconButton variant="secondary" icon="plus" onClick={handleAddChild} className="mt-4" />
              </>
            )}
          </FormField>
          <div className="pt-4">
            <FormSubmit
              buttons={[
                {
                  size: "md",
                  ariaLabel: "button",
                  text: <FormattedMessage id="utils.save" />,
                  type: "submit",
                  disabled: isPending,
                },
              ]}
              errorMessage={postError?.message}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PassingAwayForm;
