import { PlannerInputFields, Button, FormField, Input } from "@fonk-gitlab/bpfd-portal";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSalarySchema } from "../../../hooks/schemas/generalSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlannerUpdatePayload } from "../../../lib/types";
import { usePlanner } from "../../../context/PlannerContext";
import { useMutation } from "@tanstack/react-query";
import { updatePlanner } from "../../../react-query/mutations";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../../utils/token";

interface PlannerPredictionHeaderProps {
  isActive: boolean;
  employment: {
    salary: number;
    hours: number;
  };
}

interface EmploymentStringified {
  salary: string;
  hours: string;
}

const PlannerPredictionHeader: React.FC<PlannerPredictionHeaderProps> = ({ isActive, employment }) => {
  const { planner } = usePlanner() || {};
  const intl = useIntl();

  const salary = employment.salary.toString();
  const hours = employment.hours.toString();

  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updatePlanner,
    onError: async (postError, data): Promise<void> => {
      console.log("postError", postError);
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateAsync(data);
          }
        });
      }
      setError("salary", { message: postError.message });
    },
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, isValid, isSubmitted },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(useSalarySchema()),
    defaultValues: {
      salary: salary,
      hours: hours,
    },
  });

  const submitFunction = async (data: EmploymentStringified) => {
    const newPlanner = {
      hours: Number(data.hours),
      salary: Number(data.salary),
      situations: planner?.situations.map(
        ({
          totalCalculatedGross: _totalCalculatedGross,
          totalCalculatedNet: _totalCalculatedNet,
          totalCurrentGross: _totalCurrentGross,
          totalCurrentNet: _totalCurrentNet,
          ...keepAttrs
        }) => {
          return {
            ...keepAttrs,
            amounts: keepAttrs.amounts.map(
              ({
                info: _info,
                isTotal: _isTotal,
                calculatedGross: _calculatedGross,
                calculatedNet: _calculatedNet,
                currentNet: _currentNet,
                ...keepAttrs
              }) => keepAttrs
            ),
          };
        }
      ),
    };
    await mutateAsync(newPlanner as PlannerUpdatePayload);
    reset();
  };

  const disabledInput = isSubmitting || isPending || isActive;

  return (
    <PlannerInputFields
      buttonComponent={
        isActive ? undefined : (
          <Button
            ariaLabel="button"
            onClick={handleSubmit((data) => submitFunction(data))}
            size="md"
            target="_self"
            text={<FormattedMessage id="utils.save" />}
            type="button"
            disabled={isSubmitting || isPending || (!isValid && !isSubmitted)}
          />
        )
      }
      inputFields={
        <>
          <FormField id="gross" labelText={<FormattedMessage id="planner.header-gross-salary" />}>
            <Input id="salary" name="salary" control={control} placeholder="€" disabled={disabledInput} />
          </FormField>
          <FormField id="hours" labelText={<FormattedMessage id="planner.header-work-hours" />}>
            <Input
              id="hours"
              name="hours"
              control={control}
              placeholder={intl.formatMessage({ id: "utils.hours" })}
              disabled={disabledInput}
            />
          </FormField>
        </>
      }
    />
  );
};

export default PlannerPredictionHeader;
