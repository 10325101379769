import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { parseNumberByLocale } from "../../utils/parseNumberByLocale";
import { selectedRadioButton } from "../../utils/selectedRadioButton";

const useTrackExchangeOption = () => {
  const { getValues, watch } = useFormContext();
  const intl = useIntl();
  const locale = intl.locale;
  const exchangeTypeKey = "retirementOption.exchangeOption.exchangeType";
  const isMaxExchangeKey = "retirementOption.exchangeOption.isMaxExchange";
  const exchangeAmountKey = "retirementOption.exchangeOption.exchangeAmount";

  const exchangeTypeSelected = selectedRadioButton(getValues(exchangeTypeKey));
  const isMaxExchangeSelected = selectedRadioButton(getValues(isMaxExchangeKey));
  const exchangeAmountInput = parseNumberByLocale(locale, getValues(exchangeAmountKey));
  const [watchExchangeType, watchIsMaxExchange, watchExchangeAmount] = watch([
    exchangeTypeKey,
    isMaxExchangeKey,
    exchangeAmountKey,
  ]);
  const watchIsMaxExchangeValue = selectedRadioButton(watchIsMaxExchange);
  const watchExchangeTypeValue = selectedRadioButton(watchExchangeType);
  const watchExchangeAmountValue = parseNumberByLocale(locale, watchExchangeAmount);

  const [exchangeOptionChanges, setExchangeOptionChanges] = useState(false);
  const [exchangeType, setExchangeType] = useState(exchangeTypeSelected);
  const [isMaxExchange, setIsMaxExchange] = useState(isMaxExchangeSelected);
  const [exchangeAmount, setExchangeAmount] = useState(exchangeAmountInput);

  const isFieldsChanges =
    watchExchangeTypeValue === exchangeType &&
    watchIsMaxExchangeValue === isMaxExchange &&
    watchExchangeAmountValue === exchangeAmount;

  useEffect(() => {
    if (watchExchangeTypeValue === exchangeType) return;
    setExchangeType(watchExchangeTypeValue);
  }, [watchExchangeTypeValue, exchangeType, setExchangeType]);

  useEffect(() => {
    if (watchIsMaxExchangeValue === isMaxExchange) return;
    setIsMaxExchange(watchIsMaxExchangeValue);
  }, [watchIsMaxExchangeValue, isMaxExchange, setIsMaxExchange]);

  useEffect(() => {
    if (watchExchangeAmountValue === exchangeAmount) return;
    setExchangeAmount(watchExchangeAmountValue);
  }, [watchExchangeAmountValue, exchangeAmount, setExchangeAmount]);

  useEffect(() => {
    if (isFieldsChanges) return;
    setExchangeOptionChanges(true);
  }, [isFieldsChanges, setExchangeOptionChanges]);

  return exchangeOptionChanges;
};

export default useTrackExchangeOption;
