import { z } from "zod";
import { useIntlMessage } from "../useIntlMessage";
import { useBankAccountSchema } from "./bankAccountSchema";

const option = z.object({ value: z.string(), checked: z.boolean() });

export const usePassingAwaySchema = (hasBankAccount: boolean) => {
  const intlMessage = useIntlMessage();
  const bankAccountSchema = useBankAccountSchema();
  const dirtyString = z
    .string()
    .refine((value) => value.trim().length !== 0, { message: intlMessage("validation.required") });
  return z.object({
    bankAccount: hasBankAccount ? z.any().optional() : bankAccountSchema(),
    wageTaxOptions: z.array(option),
    awnOptions: z.array(option).optional(),
    childrenOptions: z.array(option).optional(),
    children: z
      .array(
        z
          .object({
            firstName: dirtyString,
            lastName: dirtyString,
            dob: z.date(),
            bsn: dirtyString,
            gender: z.object({
              id: z.string(),
              key: z.string().optional(),
              label: z.string(),
              value: z.string(),
            }),
          })
          .refine(
            ({ dob }) => {
              const isValid = z.date().safeParse(dob);
              return isValid.success;
            },
            { message: intlMessage("validation.dob.invalid"), path: ["dob"] }
          )
          .refine(
            ({ dob }) => {
              const currentDate = new Date();
              const nextDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + 1
              );

              const inputDate = new Date(dob);

              return inputDate < nextDay;
            },
            { message: intlMessage("validation.dob.can-not-be-in-future"), path: ["dob"] }
          )
      )
      .optional(),
  });
};

export type TPassingAwaySchema = z.infer<ReturnType<typeof usePassingAwaySchema>>;
