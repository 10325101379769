import { useState, useEffect, SetStateAction } from "react";

export interface RedirectUrls {
  DIGID: string;
  EIDAS: string;
}

interface LoginResponse {
  state: string;
  approved: boolean;
}

export const REDIRECT_URLS = "redirectUrls";
export const TRACKING_CODE = "state";

const useExternalWindow = (urlProp: string) => {
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>();

  const openLoginWindow = (e: { redirectUrls: SetStateAction<RedirectUrls | undefined>; state: string }) => {
    const width = 768;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = "External popup";
    const url = urlProp;
    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);

    // Store e.redirectUrls in local storage
    localStorage.setItem(REDIRECT_URLS, JSON.stringify(e.redirectUrls));
    localStorage.setItem(TRACKING_CODE, JSON.stringify(e.state));
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup || externalPopup.closed) {
        clearInterval(timer);
        setExternalPopup(null);
        return;
      }

      try {
        const currentUrl = externalPopup.location.href;
        if (currentUrl.includes("data")) {
          // searches for the approval response in the url data parameter
          const searchParams = new URL(currentUrl).searchParams;
          const data = searchParams.get("data");
          if (data) {
            setLoginResponse(JSON.parse(atob(data)));
          }
          externalPopup.close();
          setExternalPopup(null);
          clearInterval(timer);
        }
      } catch (error) {
        // Ignore cross-origin errors
        if (error instanceof DOMException && error.name === "SecurityError") {
          console.log("Cross-origin access blocked");
        } else {
          console.error("Unexpected error:", error);
        }
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [externalPopup, loginResponse]);

  return { openLoginWindow, externalPopup, loginResponse, setLoginResponse };
};

export default useExternalWindow;
