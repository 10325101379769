import {
  DataList,
  FormField,
  FormImportantMessage,
  FormTextBlock,
  Input,
  Popup,
  RadioButtonProps,
  RadioButtons,
} from "@fonk-gitlab/bpfd-portal";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { usePlanner } from "../../../context/PlannerContext";
import { ExchangeType, UseMaximumExchange } from "../../../lib/types";
import { retiringSoonResourceLink } from "../../../utils/pensionLinks";

const ExchangeOPPPOption = () => {
  const planner = usePlanner()?.planner;
  const intl = useIntl();
  const { setValue, watch, control } = useFormContext();

  const exchangeTypeKey = "retirementOption.exchangeOption.exchangeType";
  const isMaxExchangeKey = "retirementOption.exchangeOption.isMaxExchange";
  const exchangeAmountKey = "retirementOption.exchangeOption.exchangeAmount";

  const exchange = planner?.retirement?.exchange;
  const isSurrenderAtRetirement = exchange?.isSurrenderAtRetirement;
  const isWarningOPPP = exchange?.isWarningOPPP;
  const exchangeType = exchange?.typeOfExchange;
  const currentOP = exchange?.currentOP ?? 0;
  const currentPP = exchange?.currentPP ?? 0;
  const afterExchangeOP = exchange?.afterExchangeOP ?? 0;
  const afterExchangePP = exchange?.afterExchangePP ?? 0;
  const defaultMaxExchangeOP = exchange?.maxExchangeOP ?? 0;
  const defaultMaxExchangePP = exchange?.maxExchangePP ?? 0;

  const exchangeTypeOptions: RadioButtonProps[] = useMemo(() => {
    const showBothOptions = defaultMaxExchangePP > 0 && defaultMaxExchangeOP > 0;
    return [ExchangeType.OPPP, ExchangeType.PPOP]
      .map((type) => ({
        checked:
          (type === ExchangeType.OPPP && defaultMaxExchangePP === 0) ||
          (type === ExchangeType.PPOP && defaultMaxExchangeOP === 0) ||
          type === exchangeType,
        id: type,
        label: <FormattedMessage id={`planner.exchange.${type}`} />,
        value: type,
      }))
      .filter(
        (option) =>
          showBothOptions ||
          (option.id === ExchangeType.OPPP && defaultMaxExchangePP === 0) ||
          (option.id === ExchangeType.PPOP && defaultMaxExchangeOP === 0)
      );
  }, [exchangeType, defaultMaxExchangeOP, defaultMaxExchangePP]);

  const exchangeAmountOptions: RadioButtonProps[] = useMemo(() => {
    return [UseMaximumExchange.YES, UseMaximumExchange.NO].map((option) => ({
      checked: option === UseMaximumExchange.YES && exchangeType !== null,
      id: option,
      label: <FormattedMessage id={`utils.${option}`} />,
      value: option,
    }));
  }, [exchangeType]);

  const [watchedExchangeType, watchedIsMaxExchange] = watch([exchangeTypeKey, isMaxExchangeKey]);
  const [isDisabledAmountInput, setIsDisabledAmountInput] = useState(true);
  const [typeOptions, setTypeOptions] = useState(exchangeTypeOptions);
  const [amountOptions, setAmountOptions] = useState(exchangeAmountOptions);
  const [info, setInfo] = useState(false);
  const isDisplayWarning = !watchedExchangeType || !watchedIsMaxExchange;

  const handleChangeTypeOption = (updatedOption: RadioButtonProps[]) => {
    setTypeOptions(updatedOption);
  };

  const handleChangeAmountOption = (updatedOption: RadioButtonProps[]) => {
    setAmountOptions(updatedOption);
  };

  const onClickHandler = () => {
    setInfo((prevState) => !prevState);
  };

  useEffect(() => {
    exchangeTypeOptions && setValue(exchangeTypeKey, exchangeTypeOptions);
  }, [setValue, exchangeTypeOptions]);

  useEffect(() => {
    const isExchangeTypeSelected =
      watchedExchangeType?.some((option: RadioButtonProps) => option.checked) ?? false;
    if (!watchedExchangeType || !isExchangeTypeSelected) return;
    const newAmountOptions = exchangeAmountOptions.map((option: RadioButtonProps) => ({
      ...option,
      checked: option.value === UseMaximumExchange.YES,
    }));
    handleChangeTypeOption(watchedExchangeType);
    handleChangeAmountOption(newAmountOptions);
    setValue(isMaxExchangeKey, newAmountOptions);
  }, [watchedExchangeType, setValue, exchangeAmountOptions]);

  useEffect(() => {
    if (!watchedIsMaxExchange) return;

    const selectedAmountOption = watchedIsMaxExchange?.find((option: RadioButtonProps) => option.checked);
    const exchangeTypeSelected = watchedExchangeType?.find((option: RadioButtonProps) => option.checked);
    const maxExchangeAmount =
      exchangeTypeSelected?.id === ExchangeType.OPPP ? defaultMaxExchangeOP : defaultMaxExchangePP;
    if (selectedAmountOption?.value === UseMaximumExchange.YES) {
      setIsDisabledAmountInput(true);
      setValue(exchangeAmountKey, intl.formatNumber(maxExchangeAmount), { shouldValidate: true });
    }

    if (selectedAmountOption?.value === UseMaximumExchange.NO) {
      setIsDisabledAmountInput(false);
      setValue(exchangeAmountKey, "");
    }
  }, [watchedIsMaxExchange, watchedExchangeType, setValue, defaultMaxExchangeOP, defaultMaxExchangePP, intl]);

  return (
    <>
      <FormTextBlock
        body={<FormattedMessage id="planner.exchange.exchange-pp-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.exchange.exchange-pp-title" />}
      />
      <FormTextBlock
        body={<FormattedMessage id="planner.exchange.exchange-op-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.exchange.exchange-op-title" />}
        showIcon
        onIconClick={onClickHandler}
      />
      <Popup
        open={info}
        body={
          <FormattedMessage
            id="planner.exchange.for-more-info-icon"
            values={{
              link: (
                <Link to={retiringSoonResourceLink} target="_blank">
                  <FormattedMessage id="planner.exchange.altText-link" />
                </Link>
              ),
            }}
          />
        }
        onClose={onClickHandler}
        popupVariant="information"
      />
      {isSurrenderAtRetirement && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.exchange.surrender-at-retirement" />}
          className="col-span-12 mb-2"
        />
      )}
      {isWarningOPPP && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.exchange.amount-override" />}
          className="col-span-12 mb-2"
        />
      )}
      {isDisplayWarning && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.warning.required-fields" />}
          className="col-span-12 mb-15"
        />
      )}
      <FormField
        className="col-span-12"
        id={exchangeTypeKey}
        labelText={<FormattedMessage id="planner.exchange.exchange-type-title" />}>
        <RadioButtons
          name={exchangeTypeKey}
          options={typeOptions}
          control={control}
          onChange={handleChangeTypeOption}
        />
      </FormField>
      <DataList
        className="col-span-12"
        columnVisibility={[true, true]}
        dataRows={[
          {
            header: "",
            index: "0",
            inputData: [
              {
                index: "1",
                label: intl.formatMessage({ id: "planner.exchange.op" }),
                object1: { strikethrough: false, value: intl.formatNumber(currentOP) },
                object2: { strikethrough: false, value: intl.formatNumber(afterExchangeOP) },
              },
              {
                index: "2",
                label: intl.formatMessage({ id: "planner.exchange.pp" }),
                object1: { strikethrough: false, value: intl.formatNumber(currentPP) },
                object2: { strikethrough: false, value: intl.formatNumber(afterExchangePP) },
              },
            ],
          },
        ]}
        itemHeaders={{
          header1: "",
          header2: <FormattedMessage id="planner.exchange.current" />,
          header3: <FormattedMessage id="planner.exchange.after-exchange" />,
        }}
        totalVisibleColumns={3}
      />
      <FormField
        className="col-span-12"
        id={isMaxExchangeKey}
        labelText={<FormattedMessage id="planner.exchange.maximum-exchange" />}>
        <RadioButtons
          name={isMaxExchangeKey}
          options={amountOptions}
          control={control}
          onChange={handleChangeAmountOption}
        />
      </FormField>
      <FormField
        className="col-span-12"
        id={exchangeAmountKey}
        labelText={<FormattedMessage id="planner.exchange.amount" />}>
        <Input
          id={exchangeAmountKey}
          name={exchangeAmountKey}
          placeholder="€ 0,00"
          control={control}
          disabled={isDisabledAmountInput}
        />
      </FormField>
    </>
  );
};

export default ExchangeOPPPOption;
