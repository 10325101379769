import { useCallback, useEffect, useRef, useState } from "react";

const useScrollTo = <T extends Element>() => {
  const ref = useRef<T>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  const scrollToView = useCallback(() => {
    setShouldScrollTo(true);
  }, []);

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      setShouldScrollTo(false);
    }
  }, [shouldScrollTo]);

  return { ref, scrollToView };
};

export default useScrollTo;
