import { useIntl } from "react-intl";
import { z } from "zod";
import { useIntlMessage } from "../useIntlMessage";

export const useGivenNameSchema = () => {
  const intlMessage = useIntlMessage();

  const displayNameRegex = /^.+$/;
  const BLOCK_SQL = /.*[="'<>%[\]{}\\]+.*/;
  const BLOCK_SPECIAL_CHARACTERS = /[@!$€~³¤¼½¾‘+_0-9]/;
  const BLOCK_HTML = /<.*?>/;
  const BLOCK_EMOJI = /\p{Emoji}/u;
  return z.object({
    givenName: z
      .string()
      .min(1, intlMessage("validation.given-name.min"))
      .max(30, intlMessage("validation.given-name.max"))
      .refine((value: string) => !BLOCK_SQL.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => !BLOCK_HTML.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => !BLOCK_SPECIAL_CHARACTERS.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => !BLOCK_EMOJI.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => displayNameRegex.test(value), intlMessage("validation.special-characters")),
  });
};

export type TGivenNameSchema = z.infer<ReturnType<typeof useGivenNameSchema>>;

export const usePhoneSchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });

  const phoneRegex = /^[+]?[0-9- ]*$/;
  return z.object({
    phoneNumber: z
      .string()
      .regex(phoneRegex, intlMessage("validation.phone-number.format"))
      .refine((value) => value.replace(/\D/g, "").length >= 8, intlMessage("validation.phone-number.length"))
      .refine(
        (value) => value.replace(/\D/g, "").length <= 15,
        intlMessage("validation.phone-number.length")
      ),
  });
};

export type TPhoneSchema = z.infer<ReturnType<typeof usePhoneSchema>>;

export const useSalarySchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });
  const intlTypeError = (id: string): { invalid_type_error: string } => ({
    invalid_type_error: intlMessage(id),
  });

  return z.object({
    salary: z.coerce
      .number(intlTypeError("validation.salary.format"))
      .positive(intlMessage("validation.salary.positive"))
      .max(999999.99, intlMessage("validation.salary.max-header")),
    hours: z.coerce
      .number(intlTypeError("validation.hours.format"))
      .positive(intlMessage("validation.hours.positive"))
      .max(99.99, intlMessage("validation.hours.max-header")),
  });
};

export type TSalarySchema = z.infer<ReturnType<typeof useSalarySchema>>;
