import { ExchangeArrayItem, GenericRadioItem, RetirementPayload, RetirementRenamed } from "../lib/types";
import filterUnselected from "./filterUnselected";
import { apiKeys } from "./renameKeys";

interface SurrenderItem {
  isRefrainFromSurrenderSelected?: boolean;
  isSurrenderSelected?: boolean;
  ageMonth?: number;
  ageYear?: number;
  calendarMonth?: number;
  calendarYear?: number;
}

const handleSurrender = (item: SurrenderItem) => {
  const {
    isRefrainFromSurrenderSelected,
    ageMonth,
    ageYear,
    calendarMonth: _calendarMonth,
    calendarYear: _calendarYear,
    isSurrenderSelected: _isSurrenderSelected,
    ...rest
  } = item;

  const test = {
    ...(isRefrainFromSurrenderSelected !== undefined && {
      isSelected: isRefrainFromSurrenderSelected,
    }),
    ...(ageMonth !== undefined && { surrenderMonths: ageMonth }),
    ...(ageYear !== undefined && { surrenderYears: ageYear }),
    ...rest,
  };

  return test;
};

interface EarlyOrLaterItem {
  isEarlierOrLaterRetirementSelected?: boolean;
  fullyOrPartial?: { value?: string };
  fullCalendarMonth?: number;
  fullCalendarYear?: number;
  fullAgeYear?: number;
  fullAgeMonth?: number;
  partialAgeYear?: number;
  partialAgeMonth?: number;
  partialCalendarMonth?: number;
  partialCalendarYear?: number;
}

const handleEarlyOrLater = (item: EarlyOrLaterItem) => {
  const {
    isEarlierOrLaterRetirementSelected,
    fullyOrPartial,
    fullCalendarMonth: _fullCalendarMonth,
    fullCalendarYear: _fullCalendarYear,
    fullAgeYear,
    fullAgeMonth,
    partialAgeYear,
    partialAgeMonth,
    partialCalendarMonth: _partialCalendarMonth,
    partialCalendarYear: _partialCalendarYear,
    ...rest
  } = item;
  return {
    ...rest,
    ...(isEarlierOrLaterRetirementSelected !== undefined && {
      isSelected: isEarlierOrLaterRetirementSelected,
    }),
    ...(fullyOrPartial?.value !== undefined && {
      percentage: fullyOrPartial.value,
    }),
    ...{ fullyYears: fullAgeYear ?? 0 },
    ...{ fullyMonths: fullAgeMonth ?? 0 },
    ...{ partlyYears: partialAgeYear ?? 0 },
    ...{ partlyMonths: partialAgeMonth ?? 0 },
  };
};

interface BridgingItem {
  maxExchangeAowAmount?: number;
  bridgingAmount?: number;
}

const handleBridging = (item: BridgingItem) => {
  const { maxExchangeAowAmount: _maxExchangeAowAmount, bridgingAmount: exchangeAmount, ...rest } = item;
  return {
    exchangeAmount,
    ...rest,
  };
};

interface HighLowItem {
  calendarMonth?: number;
  calendarYear?: number;
  numberOfMonths?: number;
  Exchange?: Record<string, ExchangeArrayItem>;
}

const handleHighLow = (item: HighLowItem) => {
  const {
    calendarMonth: _calendarMonth,
    calendarYear: _calendarYear,
    numberOfMonths,
    Exchange: highLowExchange,
    ...rest
  } = item;
  let type = undefined;
  if (highLowExchange) {
    const HLExchangeArray: ExchangeArrayItem[] = Object.values(highLowExchange);
    type = HLExchangeArray.find((item) => item.checked)?.value;
  }
  return {
    ...rest,
    ...(numberOfMonths !== undefined && { months: numberOfMonths }),
    ...(type !== undefined && { type }),
  };
};

interface ExchangeItem {
  exchangeType?: string | GenericRadioItem[] | null;
  exchangeAmount?: number;
  isMaxExchange?: GenericRadioItem[] | boolean;
}

const handleExchange = (item: ExchangeItem) => {
  const { isMaxExchange, exchangeType, ...rest } = item;

  let formattedIsMaxExchange;
  if (isMaxExchange && typeof isMaxExchange !== "boolean") {
    const exchangeArray = Object.values(isMaxExchange);
    formattedIsMaxExchange = exchangeArray.find((item) => item.checked)?.checked;
  }

  let formattedType;
  if (exchangeType && typeof exchangeType !== "string") {
    const typeArray = Object.values(exchangeType);
    formattedType = typeArray.find((item) => item.checked)?.value;
  }

  return {
    ...{ isMaxExchange: formattedIsMaxExchange ?? isMaxExchange },
    ...{ exchangeType: formattedType ?? exchangeType },
    ...rest,
  };
};

const formatRetirementToAPI = (retirement: RetirementRenamed): RetirementPayload => {
  const newRetirement = { ...retirement };

  for (const key in newRetirement) {
    if (Object.prototype.hasOwnProperty.call(newRetirement, key)) {
      switch (key) {
        case apiKeys.surrender:
          newRetirement[key] = handleSurrender(newRetirement[key]);
          break;
        case apiKeys.earlyOrLater:
          newRetirement[key] = handleEarlyOrLater(newRetirement[key]);
          break;
        case apiKeys.bridging:
          newRetirement[key] = handleBridging(newRetirement[key]);
          break;
        case apiKeys.highLow:
          newRetirement[key] = handleHighLow(newRetirement[key]) as HighLowItem;
          break;
        case apiKeys.exchange:
          newRetirement[key] = handleExchange(newRetirement[key]);
          break;
        default:
          break;
      }
    }
  }

  return filterUnselected(newRetirement);
};

export default formatRetirementToAPI;
