import {
  Button,
  Checkbox,
  FormImportantMessage,
  FormPopup,
  FormTextBlock,
  GenericTextBlock,
} from "@fonk-gitlab/bpfd-portal";
import { SetStateAction, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@tanstack/react-query";
import AcceptanceUserFinancialInfo from "../../../molecules/plannerAcceptance/AcceptanceUserFinancialInfo";
import useExternalWindow, { RedirectUrls } from "../../../../hooks/useExternalWindow";
import { useAuthentication } from "../../../../context/AuthenticatedContext";
import { HREF } from "../../../../routes/routes";
import ThankYouMessage from "../../../atoms/ThankYouMessage";
import { updatePartnerAcceptance } from "../../../../react-query/mutations";

import "./PartnerAcceptancePopup.css";

enum agreementStatus {
  AGREED = "agreed",
  APPROVED = "approved",
  AGREE = "agree",
}

interface PartnerAcceptancePopupProps {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  additionalPayload: object | undefined;
}

const PartnerAcceptancePopup: React.FC<PartnerAcceptancePopupProps> = ({ showPopup, setShowPopup }) => {
  const user = useAuthentication()?.user;
  const { openLoginWindow, externalPopup, loginResponse, setLoginResponse } = useExternalWindow(
    HREF.LOGIN_PARTNER
  );

  const { mutate, reset } = useMutation({
    mutationFn: updatePartnerAcceptance,
    onSuccess: (data: { redirectUrls: SetStateAction<RedirectUrls | undefined>; state: string }) => {
      openLoginWindow(data);
    },
    onError: async (postError: unknown): Promise<void> => {
      console.log("postError", postError);
      reset();
    },
  });

  const [isConditionsChecked, setIsConditionsChecked] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [hasPartnerSubmitted, setHasPartnerSubmitted] = useState(false);
  const [isBsnError, setIsBsnError] = useState(false);
  const [isAcceptedByPartner, setIsAcceptedByPartner] = useState(false);
  const [isAcceptedByUser, setIsAcceptedByUser] = useState(false);

  const partnerBSN = user?.partnerBsn;
  const partnerAcceptance = isAcceptedByPartner ? agreementStatus.APPROVED : agreementStatus.AGREE;
  const userAcceptance = isAcceptedByUser ? agreementStatus.AGREED : agreementStatus.AGREE;
  const totalAcceptance = isAcceptedByPartner && isAcceptedByUser;

  const isWindowOpen = externalPopup !== null;

  const approvalStatus = loginResponse?.approved;
  const approvalTrackingCode = loginResponse?.state;
  const isApproved = approvalStatus && approvalTrackingCode === loginResponse.state && !isAcceptedByPartner;

  const PlannerRetirementOptions = [
    // replace with actual data
    {
      text: "From 66 year old you will receive your full pension",
    },
    {
      text: "Exchange of € 77,34 gross partner's pension for additional retirement pension",
    },
  ];

  const handleAcceptance = (cb: () => void) => {
    if (!isConditionsChecked) {
      setIsCheckboxError(true);
      return;
    }
    setIsCheckboxError(false);
    cb();
  };

  const handlePartnerAcceptance = () => {
    if (partnerBSN && !isWindowOpen) {
      // sends get request to backend
      mutate();
    }
  };

  const handleUserAcceptance = () => {
    setIsAcceptedByUser(true);
    setIsConditionsChecked(false);
  };

  useEffect(() => {
    if (loginResponse && !hasPartnerSubmitted) {
      setHasPartnerSubmitted(true);
    }

    if (hasPartnerSubmitted && isApproved) {
      setIsAcceptedByPartner(true);
      setLoginResponse(undefined);
      setIsConditionsChecked(false);
    }
  }, [hasPartnerSubmitted, loginResponse, isApproved, setLoginResponse]);

  useEffect(() => {
    if (hasPartnerSubmitted && isApproved && isBsnError) {
      setIsBsnError(false);
    }

    if (hasPartnerSubmitted && !isApproved && !isAcceptedByPartner) {
      setIsBsnError(true);
    }
  }, [hasPartnerSubmitted, isAcceptedByPartner, isApproved, isBsnError]);

  return (
    <FormPopup
      ariaLabelCloseIcon="Sluiten"
      open={showPopup}
      onClose={() => setShowPopup(false)}
      preventBackdropClick>
      <div className="text-content">
        <FormTextBlock
          className="pb-0"
          ariaLabelIconButton="informatie"
          heading={!totalAcceptance && <FormattedMessage id="planner.acceptance.apply" />}
          body={
            totalAcceptance ? (
              <ThankYouMessage />
            ) : (
              <>
                <div>
                  {(!partnerBSN || isBsnError) && (
                    <FormImportantMessage
                      body={<FormattedMessage id={"error.bsn-validation-fail"} />}
                      className="flex w-full pb-4"
                    />
                  )}
                  <GenericTextBlock
                    size="s"
                    content={
                      <>
                        <FormattedMessage id="planner.acceptance.choose" />
                        <ul>
                          {PlannerRetirementOptions.map((option) => (
                            <li key={option.text}>{option.text}</li>
                          ))}
                        </ul>
                        <FormattedMessage id="planner.acceptance.main-text" />
                        <div className="mt-2">
                          <AcceptanceUserFinancialInfo />
                        </div>
                      </>
                    }
                  />
                </div>
                <Checkbox
                  id="default-checkbox"
                  label={<FormattedMessage id="planner.partner-acceptance-sign-warning" />}
                  feedbackMessage={<FormattedMessage id="planner.partner-acceptance-sign-error" />}
                  onChange={() => {
                    if (!isWindowOpen) setIsConditionsChecked(!isConditionsChecked);
                  }}
                  error={isCheckboxError}
                  checked={isConditionsChecked}
                />
                <GenericTextBlock
                  className="positive-mb-1 negative-mt-1"
                  size="s"
                  content={<FormattedMessage id="planner.partner-acceptance-exchange" />}
                />
              </>
            )
          }
        />
        <div className="buttons-row">
          {!totalAcceptance && (
            <>
              <div className="cancel-button">
                <Button
                  text={<FormattedMessage id="utils.cancel" />}
                  ariaLabel={<FormattedMessage id="utils.cancel" />}
                  onClick={() => setShowPopup(false)}
                  arrowPosition="left"
                  size="md"
                  type="button"
                  target="_self"
                  variant="primary"
                />
              </div>
              <div className="partner-button">
                <Button
                  text={<FormattedMessage id={`utils.${partnerAcceptance}-by-partner`} />}
                  ariaLabel={<FormattedMessage id={`utils.${partnerAcceptance}-by-partner`} />}
                  onClick={() => handleAcceptance(() => handlePartnerAcceptance())}
                  disabled={isAcceptedByPartner || isWindowOpen}
                  icon={isAcceptedByPartner ? "checkmark" : undefined}
                  size="md"
                  type="submit"
                />
              </div>
              <div>
                <Button
                  text={<FormattedMessage id={`utils.${userAcceptance}`} />}
                  ariaLabel={<FormattedMessage id={`utils.${userAcceptance}`} />}
                  onClick={() => handleAcceptance(() => handleUserAcceptance())}
                  disabled={isAcceptedByUser}
                  icon={isAcceptedByUser ? "checkmark" : undefined}
                  size="md"
                  type="submit"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </FormPopup>
  );
};

export default PartnerAcceptancePopup;
