import { Accordion, AccordionItem, FormSubmit } from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import {
  EmploymentAndRetirementRenamed,
  ParticipationStatus,
  PlannerCalculatePayload,
  Retirement,
} from "../../../lib/types";
import { limitAge } from "../../../utils/plannerCalendar";
import { getRetirementLimits } from "../../../utils/retirement";
import PlannerEmploymentOptions from "./PlannerEmploymentOptions";
import PlannerOtherOptions from "./PlannerOtherOptions";
import PlannerRetirementOptions from "./PlannerRetirementOptions";
import { EarlierLaterFully } from "../../../lib/constants";
import { usePlannerSchema, TPlannerSchema } from "../../../hooks/schemas/plannerSchema";
import { renameKeys } from "../../../utils/renameKeys";
import formatSituationPayload from "../../../utils/formatSituationToAPI";
import formatEmploymentToAPI from "../../../utils/formatEmploymentToAPI";
import formatRetirementToAPI from "../../../utils/formatRetirementToAPI";
import formatScenarioToForm from "../../../utils/formatScenarioToForm";
import { useEffect, useMemo } from "react";
import useScrollTo from "../../../hooks/useScrollTo";

interface PlannerOptionsProps {
  isRetrieved: boolean;
}

const PlannerOptions: React.FC<PlannerOptionsProps> = ({ isRetrieved }) => {
  const intl = useIntl();
  const { ref, scrollToView } = useScrollTo<HTMLDivElement>();
  const {
    planner,
    calculate,
    isPending,
    isSuccess: isCalculateSuccess,
    errorCalculate,
    scenario,
  } = usePlanner() || {};

  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const isEmploymentActive = isActive || planner?.employment?.salary;
  // dateOfBirth is checked in Planner.tsx and retirementDate is calculated with dateOfBirth
  // 0 is used to avoid @typescript-eslint/no-non-null-assertion error
  const retirementDate = planner?.participation?.defaultRetirementDate ?? "0";
  const dateOfBirth = planner?.person?.dateOfBirth ?? "0";
  const retirement = useMemo(() => planner?.retirement ?? ({} as Retirement), [planner?.retirement]);

  const defaultFormValues = useMemo(
    () => ({
      employmentOptions: {
        workingTimeOption: {
          isSelected: false,
        },
        earningSalaryOption: {
          isSelected: false,
        },
        quittingWorkOption: {
          isSelected: false,
        },
      },
      retirementOption: {
        surrenderOptions: {
          isSurrenderSelected: false,
          isRefrainFromSurrenderSelected: false,
        },
        earlierOrLaterRetirementOptions: {
          isEarlierOrLaterRetirementSelected: false,
          fullyOrPartial: {
            id: EarlierLaterFully.toString(),
            label: intl.formatMessage({ id: "utils.fully" }),
            value: EarlierLaterFully,
          },
        },
        exchangeOption: {
          isSelected: false,
        },
        bridgingOption: {
          isSelected: false,
          maxExchangeAowAmount: retirement?.maxExchangeBridging ?? 0,
        },
        highLowLowHigh: {
          isSelected: false,
        },
      },
    }),
    [intl, retirement]
  );

  const methods = useForm({
    mode: "onChange",
    resolver: zodResolver(
      usePlannerSchema({
        isActive,
        limitAge: limitAge(new Date(dateOfBirth), new Date(retirementDate)),
        retirementYear: new Date(retirementDate).getFullYear(),
        retirementOptionsLimits: {
          ...getRetirementLimits({
            retirement,
            participantDOB: dateOfBirth,
          }),
        },
      })
    ),
    defaultValues: defaultFormValues,
  });
  const {
    formState: { isSubmitting, errors },
    reset,
  } = methods;

  const onSubmit = (submitData: TPlannerSchema) => {
    if (calculate) {
      const renamedKeys = renameKeys(submitData) as EmploymentAndRetirementRenamed;
      const payload: PlannerCalculatePayload = {
        hours: Number(planner?.employment.hours),
        salary: Number(planner?.employment.salary),
        situations: formatSituationPayload(planner?.situations),
        employment: formatEmploymentToAPI(renamedKeys.employment),
        retirement: formatRetirementToAPI(renamedKeys.retirement),
      };
      calculate(payload);
    }
  };

  useEffect(() => {
    if (isRetrieved && scenario && defaultFormValues !== formatScenarioToForm(scenario, intl)) {
      reset(formatScenarioToForm(scenario, intl));
    }
  }, [defaultFormValues, intl, isRetrieved, reset, scenario]);

  useEffect(() => {
    if (isCalculateSuccess) {
      scrollToView();
    }
  }, [isCalculateSuccess, scrollToView]);

  const isObjectNotEmpty = (obj: object): boolean => {
    return Object.keys(obj).length > 0;
  };

  return (
    <div ref={ref}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            keyExtractor={(item) => item.key}
            className="gap-y-2 flex flex-col"
            accordionItems={[
              isEmploymentActive && (
                <AccordionItem
                  id="employmentOptions"
                  key="1"
                  title={<FormattedMessage id="planner.employment-heading-section" />}
                  content={<PlannerEmploymentOptions />}
                />
              ),
              <AccordionItem
                id="retirementOptions"
                key="2"
                title={<FormattedMessage id="planner.retirement-heading-section" />}
                content={<PlannerRetirementOptions />}
              />,
              <AccordionItem
                id="otherOptions"
                key="3"
                title={<FormattedMessage id="planner.other-heading-section" />}
                content={<PlannerOtherOptions />}
              />,
            ].filter(Boolean)}
          />
          <div className="pt-4">
            <FormSubmit
              buttons={[
                {
                  ariaLabel: "submit",
                  size: "md",
                  text: <FormattedMessage id="planner.calculate" />,
                  type: "submit",
                  disabled: !!isSubmitting || isPending,
                },
              ]}
              errorMessage={
                isObjectNotEmpty(errors) ? (
                  <FormattedMessage id={"planner.calculate-error"} />
                ) : (
                  errorCalculate?.message
                )
              }
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PlannerOptions;
